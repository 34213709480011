import React from 'react'
import {Auth} from 'aws-amplify';
import logo from '../assets/img/logo-hmi-white.png';

const navStyle = {
    backgroundColor: '#222952'
}
const navLink = {
    color: '#fff'
}

function logout(e) {
    e.preventDefault();
    Auth.signOut()
    .then(data => {
    })
    .catch(err => console.log(err));
}

function onclickPrevent(e) {
    e.preventDefault();
}

const Header = (props => {
    return (
        <header>
            <nav className="navbar" style={navStyle}>
                <a className="navbar-brand" href="/"  onClick={onclickPrevent}>
                    <img src={logo} width="180px" alt=""/>
                </a>
                <div className="" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item text-right">
                            <a className="nav-link sprite-bck log-out" style={navLink} href="/" onClick={logout}><span className="sr-only">Logout</span></a>
                        </li>                        
                        <li className="nav-item">
                            <a className="nav-link pt-0" style={navLink} href="/" onClick={onclickPrevent}>{props.useremail}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
})

export default Header;