import React, { Component } from 'react'
import {connect} from 'react-redux';
import Pageheader from '../../components/pageheader';
import Navtab from '../../components/navtab';
import { Trans, withI18n } from "react-i18next";
import { fetchEnterprises } from '../../store/appdata/enterprise/enterpriseActions';
import Form from './components/form';
import Table from './components/table';

class ManageEnterprises extends Component {
    
    componentDidMount = () => { 
        this.props.fetchEnterprises();    
    }

    render() {
        return (
            <div className="content-wrapper">
                <Pageheader title={<Trans>manageEnterprises</Trans>} />
                <div className="row no-gutters">
                
                {!this.props.editedItem ? (
                    <Navtab tabs={[{ id: 1, name: <Trans>addEnterprise</Trans> }]} />
                    ): (
                     <Navtab tabs={[{ id: 1, name: <Trans>updateEnterprise</Trans> }]} />
                    )}
                
                </div>
                    <Form />
                    <Table 
                        tabs={[{ id: 1, name: 'Enterprises' }]}
                    />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    editedItem: state.enterprises.editedItem
})
const mapDispatchToProps = {
    fetchEnterprises,
};

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(ManageEnterprises));
