import { createStore } from 'redux';

//import { updateUser } from './actions';
import userProfile from './reducers';

const store = createStore(userProfile);

export default store;
// export {
//   updateUser
// }
export { default as AmplifyBridge } from './AmplifyBridge';