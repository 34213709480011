import React, { Component } from 'react'
import {connect} from 'react-redux';
import moment from 'moment'
import { ClipLoader } from 'react-spinners';
import { Trans, withI18n } from "react-i18next";
import Alert from 'react-s-alert';
import { confirmAlert } from 'react-confirm-alert';
import Navtab from '../../../components/navtab';
import TableNav from "./tableNav"
import { fetchEnterprises, startEdit, deleteEnterprises, updateStatus } from '../../../store/appdata/enterprise/enterpriseActions';
import { showLoader, hideLoader } from '../../../components/loadingComponent';

const filters = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ]

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            checkedItems: new Map(),
            totalSelected: 0,
            filtered: filters[0]
        }
    }

    componentDidMount = () => {

    }

    loadMore = () => {
        const loadmore = true;        
        this.props.fetchEnterprises({loadmore});          
      }

    filterItems = (event) => {
        this.setState({filtered: event}, () => {
            this.props.fetchEnterprises({status: event.value});
        })
    }

    edit = (id) => {
        let item = this.props.items.find(item => item.id === id);        
       this.props.startEdit(item);
    }
    delete = (id) => {
        const item = this.props.items.find(item => item.id === id);
        const data = {
            message: `${this.props.t('Do you want to delete')} ${item.eInfo.name}?`,
            btnText: this.props.t('Yes, Delete!')                                
        }
        this.confirmPopUp( data, () => {
            this.props.deleteEnterprises([id]).then(() => {
                Alert.success(`${item.eInfo.name} ${this.props.t('deleted')}.`, {
                    position: 'top-right',
                    effect: 'stackslide',                   
                });
            })
        })
    }

    multiDelete = () => {

        const ids = [ ...this.state.checkedItems.keys() ]
        .filter(id => id !== 'all');

        const data = {
            message: this.props.t(`Do you want to delete the selected enterprises?`),
            btnText: this.props.t('Yes, Delete!')             
        }
        this.confirmPopUp( data, () => {
            showLoader({target: 'dataTable'});
            this.props.deleteEnterprises(ids).then(() => {
                this.updateSuccess();
                Alert.success(this.props.t('The selected enterprises deleted.'), {
                    position: 'top-right',
                    effect: 'stackslide',                   
                });
            })
        })
    }

    changeStatus = (status, ids) => {

        // const users = [ ...this.state.checkedItems.keys() ]
        // .filter(id => id !== 'all')
        // .map(id => this.props.users.find(user => user.id === id));
        // console.log(ids);
        // return;
        const data = {
            message: `${this.props.t('Do you want to change status from')} ${this.props.status} ${this.props.t('to')} ${status}?`,
            btnText: this.props.t('Yes, Change!')                                
        }
        this.confirmPopUp( data, () => {
            showLoader({target: 'dataTable'});
            this.props.updateStatus(ids, status).then(() => {
                this.updateSuccess()
                Alert.success(this.props.t(`The selected enterprise status changed.`), {
                    position: 'top-right',
                    effect: 'stackslide',                   
                });
            })
        })
    }

    multichangeStatus = (status) => {
        const ids = [ ...this.state.checkedItems.keys() ]
            .filter(id => id !== 'all');
            this.changeStatus(status, ids);        
    }

    updateSuccess = () => {
        this.setState({
            checkedItems: new Map(),
            totalSelected: 0
        }, () => {
           hideLoader();
        })
    }

    warningMessage = (message = `You can select up to 25 records`) => {        
        const data = {
            message: message,
            btnText: 'Ok!',
            hidetitle: true,
            hidecancelbtn: true               
        }
        this.confirmPopUp( data);
    }

    confirmPopUp = (data, callback) => {        
        confirmAlert({
            customUI: ({ onClose }) => {
              return (                 
                 <div className='custom-ui'>                                                 
                  {!data.hidetitle && 
                        <h1>{this.props.t('Are you sure?')}</h1>                
                    }
                  <p>{data.message}</p>
                  {!data.hidecancelbtn && 
                    <button onClick={() => {                                  
                        onClose();
                        }}>{this.props.t('No')}</button>
                  }
                  <button className="btn-delete" onClick={() => {
                     onClose();
                     if(callback)
                        callback();
                    }} ref={(c)=> c && c.focus()}>{data.btnText}</button>
                </div>               
              )
            }
          })
    }

    selectItems = (e) => {
        const selectedItems = e.target.dataset.selecteditems;
        const isChecked = e.target.checked;
        const checkedboxes = document.querySelectorAll('#dataTable tbody input[type=checkbox]:checked');
      
        let obtainedLength = this.props.items.length;
        let prevtems = null;
        if (selectedItems !== 'all') {
            if ((!isChecked && this.state.checkedItems.get('all')) || (checkedboxes.length === obtainedLength)) {
                prevtems = new Map(this.state.checkedItems)
                prevtems.set('all', isChecked);
                prevtems.set(selectedItems, isChecked);
            } else {
                prevtems = new Map(this.state.checkedItems)   
                prevtems.set(selectedItems, isChecked);
            }
        } else {
            prevtems = new Map();
            prevtems.set('all', isChecked);
            this.props.items.forEach(d => {
                prevtems.set(d.id, isChecked);
            })
        }
        const totalSelected = ([...(prevtems.values())].filter(selected => selected)).length;
        if(totalSelected <= 25) {
            this.setState({ 
                checkedItems: prevtems,
                totalSelected: totalSelected
            })
        }else {
            this.warningMessage();
        }
    }

    render() {
        let props = this.props;
        const checked = [...new Set([...this.state.checkedItems.values()])];
        return (
            <div className="row no-gutters my-3" style={{overflowX: 'auto'}}>
                <div className="nav-wrapper">
                    <Navtab tabs={props.tabs} style={{ backgroundColor: 'rgb(34, 41, 82)', color: '#fff' }} />
                </div>
                <TableNav 
                    filtered={this.state.filtered}
                    filterItems={this.filterItems}
                    filterOptions={filters}
                    checked={checked}  
                    changeStatus={this.multichangeStatus}
                    delete={this.multiDelete}
                    status={props.status}                             
                />
                
                {props.items.length > 0 ? (
                    <React.Fragment>
                    <table className="table table-bordered position-relative" id="dataTable">
                        <thead>
                            <TableHeader
                                headers={props.headers}
                                checked={this.state.checkedItems.get('all')}
                                selectItems={this.selectItems}
                                />
                           
                        </thead>
                        <tbody>
                            {props.items.map((item, index) => <TableBody 
                                key={index} 
                                item={item}
                                status={props.status}
                                checked={this.state.checkedItems.get(item.id)} 
                                selectItems={this.selectItems}
                                edit={this.edit}                               
                                delete={this.delete}
                                changeStatus={this.changeStatus}
                                edited={props.editedItem}                              
                                />
                            )}
                        </tbody>
                    </table>
                    
                        { (props.LastEvaluatedKey) && (
                            <div className="text-center col-12 loadmorewrapper">
                                { props.loadmore ? 
                                    (                      
                                        <ClipLoader
                                            className="clipLoader"      
                                            sizeUnit={"px"}
                                            size={50}
                                            color={'#fff'}
                                            loading={props.loadmore}
                                        />  
                                    ): (  
                                        <button className="load-more btn" onClick={this.loadMore}>    
                                            <Trans>loadMore</Trans>
                                        </button>
                                    )}
                                </div>
                            )
                        }
                    </React.Fragment>    

                ) : (
                        <div className="w-100 text-center alert alert-custom">
                            {!props.progress ? <span><Trans>No data found</Trans></span> : <span><Trans>Loading...</Trans></span>}
                        </div>
                    )}
            </div>
        )
    }
}

//export default CommonTable;

const mapStateToProps = (state) => {
    const {enterprises} = state;
   return ({
    items: enterprises.items,
    headers: enterprises.headers,
    LastEvaluatedKey: enterprises.LastEvaluatedKey,
    loadmore: enterprises.loadmore,
    status: enterprises.status,
    progress: enterprises.progress,
    editedItem: enterprises.editedItem
    })
}

const mapDispatchToProps = {
   fetchEnterprises,
   startEdit,
   deleteEnterprises,
   updateStatus
 };

 export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(Table));

function TableHeader({headers, checked, selectItems}) {
    return <tr>
        {headers.map((header, index) => {
            if (header.type === 'checkbox')
                return (
                    <th key={index} scope="col" className="table-box">
                        <div className="custom-control custom-checkbox">
                            <input 
                                type="checkbox"
                                className="custom-control-input"
                                id="checkboxall"
                                checked={checked ?  checked :  false}
                                data-selecteditems="all"
                                onChange={selectItems} />
                            <label className="custom-control-label" htmlFor="checkboxall"></label>
                        </div>
                    </th>
                )
            else
                return <th key={index} scope="col"><Trans>{header.name}</Trans></th>

    })}</tr>
}

function TableBody({item, checked, status, edited, ...actions}) {
    const rowclass = (edited && item.id=== edited.id) ? 'active ': '';
    return (
        <tr className={rowclass}>
        <td className="table-box">
            <div className="custom-control custom-checkbox">
                <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={checked ?  checked :  false}
                    data-selecteditems={item.id}
                    onChange={actions.selectItems}
                    id={"checkbox" + item.id} />
                <label
                    className="custom-control-label"
                    htmlFor={"checkbox" + item.id}>
                </label>
            </div>
        </td>
        <td>
            <div>{item.eInfo.name}</div>
        </td>
        <td>
            <div>{item.eInfo.owner ? `${item.eInfo.owner.userInfo.firstName} ${item.eInfo.owner.userInfo.lastName ? item.eInfo.owner.userInfo.lastName: ''}`: ''}</div>
        </td>
        <td>
            <div>{moment.utc(item.eInfo.createdAt).local().format("YYYY/MM/DD HH:mm A")}</div>
        </td>
        <td>
            <div>
                {status === 'active' &&
                    <React.Fragment>
                        <span className="pointer mr-3"
                            onClick={() => actions.edit(item.id)}
                            style={{ color: '#2f63dc' }}>
                            <Trans>edit</Trans>
                        </span>
                        |
                        <span className="pointer mr-3 ml-3"
                            onClick={(e) => actions.changeStatus('inactive', [item.id])}
                            style={{ color: '#d8a504' }}>
                            <Trans>Inactivate</Trans>
                        </span>|
                    </React.Fragment>
                }              
                {status === 'inactive' &&
                    <React.Fragment>
                        <span className="pointer mr-3 ml-3"
                            onClick={(e) => actions.changeStatus('active', [item.id])}
                            style={{ color: '#d8a504' }}>
                            <Trans>Activate</Trans>
                        </span>|
                    </React.Fragment>
                }
                <span className="pointer ml-3"
                    onClick={() => actions.delete(item.id)}
                    style={{ color: '#fc7169' }}>
                    <Trans>Delete</Trans>
                </span>
            </div>
        </td>
    </tr>)
}

