import React from 'react'

const searchIcon = (() => {
    return (
        <div className="euiFormControlLayoutIcons">
            <span className="euiFormControlLayoutCustomIcon">
                <svg className="euiIcon euiIcon--medium euiFormControlLayoutCustomIcon__icon" 
                    focusable="false"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <defs>
                        <path id="search-a" d="M11.271 11.978l3.872 3.873a.502.502 0 0 0 .708 0 .502.502 0 0 0 0-.708l-3.565-3.564c2.38-2.747 2.267-6.923-.342-9.532-2.73-2.73-7.17-2.73-9.898 0-2.728 2.729-2.728 7.17 0 9.9a6.955 6.955 0 0 0 4.949 2.05.5.5 0 0 0 0-1 5.96 5.96 0 0 1-4.242-1.757 6.01 6.01 0 0 1 0-8.486c2.337-2.34 6.143-2.34 8.484 0a6.01 6.01 0 0 1 0 8.486.5.5 0 0 0 .034.738z"></path>
                    </defs>
                    <use xlinkHref="#search-a"></use>
                    </svg>
                </span>
          </div>
    )
})

export default searchIcon;