// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "healthymindweb-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "http://healthymindweb-dev.s3-website.us-east-2.amazonaws.com"
};

export default awsmobile;
