import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  // lng: 'en',
  resources: {
    en: {
      translations: {
        // sign in page
        signIn: "Sign In",
        forgotPasswordwithQuestionMark: "FORGOT PASSWORD?",
        login: "Login",
        youDontHaveAccess: "You don't have access.",
        userName: "User Name", 
        password: "Password",
        "Incorrect username or password. Please try again" : "Incorrect username or password. Please try again",
        "Please provide username and password." : "Please provide username and password.",
        // forgot password page
        forgotPassword: "Forgot Password",
        forgotPasswordErrorMessage: "Please enter your username below and we will send you verification code to change your password.",
        generateVerificationCode: "Generate verification code",
        edit: "Edit",
        resendCode: "Resend Code",
        passwordMustMeettheFollwingRequirements: "Password must meet the following requirements:",
        "At least" : "At least ",
        "Be at least":"Be at least ",
        "one lowercase letter": "one lowercase letter",
        "one capital letter": "one capital letter",
        "one number":"one number",
        "8 characters" : "8 characters",
        passwordsDoesNotMatch: "Passwords does not match.",
        submit: "Submit",
        confirmPassword: "Confirm Password",
        enterCode : "Enter Code",
        "Verification code sent to email.": "Verification code sent to email.",
        "Verificationcodeerror": "Please enter valid verification code",
        "Username can't be empty" : "Username can't be empty",
        "Password Successfully changed." : "Password Successfully changed.",
        // change password page
        changePassword: "Change Password",
        "username": "Username",
        yourPasswordChangedSuccessfully : "Your Password changed successfully",
        newPassword : "New Password",
        "Passwords is not match.": "Passwords is not match.",
        "Password does not match the rules.": "Password does not match the rules.",
        "Password sholud contains one Special charector, one uppercase and number": "Password sholud contains one Special charector, one uppercase and number",
        // Root menu link page
        "MANAGE" : "MANAGE",
        "ENTERPRISES" : "ENTERPRISES",
        "USERS" : "USERS",
        "TEAMS" : "TEAMS",
        "ACTIVITY" : "ACTIVITY",
        "LOGS" : "LOGS",
        // manage enterprise page
        enterpriseName: "Enterprise Name",
        enterpriseOwnerName: "Enterprise Owner Name",
        status: "Status",
        Submit: "Submit",
        Update: "Update",
        Cancel: "Cancel",
        addEnterprise: "Add Enterprise",
        updateEnterprise: "Update Enterprise",
        "Enterprise added successfully." : "Enterprise added successfully.",
        "Enterprise updated successfully." : "Enterprise updated successfully.",
        "Are you sure?" : "Are you sure?",
        "You want to delete": "You want to delete",
        "enterprise deleted successfully.":"enterprise deleted successfully.",
        "No": "No",
        "to" : "to",
        "Do you want to change status from" : "Do you want to change status from",
        "Yes, Delete!": "Yes, Delete!",
        "Enterprise status updated successfully.": "Enterprise status updated successfully.",
        "Enterprises status updated successfully.": "Enterprises status updated successfully.",
        "Enterprises deleted successfully." : "Enterprises deleted successfully.",
        "You want to delete the selected items?" : "You want to delete the selected items?",
        "Enterprise name should not be empty." : "Enterprise name should not be empty.",
        "Please enter valid enterprise name." : "Please enter valid enterprise name.",
        "the selected enterprises" : "the selected enterprises",
        "Do you want to delete the selected enterprises?" : "Do you want to delete the selected enterprises?",
        'Enterprise successfully created' : 'Enterprise successfully created',
        "The selected enterprise status changed." : "The selected enterprise status changed.",
        "The selected enterprises deleted." : "The selected enterprises deleted.",
        "deleted" : "deleted",
        // common table
        'Name': 'Name',
        'Owner Name': 'Owner Name',
        'Created Date': 'Created Date',
        'Action': 'Action',
        'Team Name': 'Team Name',
        'Enterprise': 'Enterprise',
        'Date': "Date",
        "Enterprises": "Enterprises",
        "Teams": "Teams",
        "inactive": "Active",
        "active": "Inactive",
        'Inactivate' : 'Inactivate',
        'Activate' : 'Activate',
        "Delete": "Delete",
        "No data found": "No data found",
        "Loading...": "Loading...",
        deleteSelected: "Delete",
        inactiveSelected: "Inactivate",
        activeSelected: "Activate",
        invite:"Pending",
        // manage team page
        teamName: "Team Name",
        teamOwnerName: "Team Owner Name",
        addTeam: "Add Team",
        "Team added successfully." : "Team added successfully.",
        "Team updated successfully." : "Team updated successfully.",
        "team deleted successfully." : "team deleted successfully.",
        "Team status updated successfully." : "Team status updated successfully.",
        "Teams status updated successfully." : "Teams status updated successfully.",
        "Teams deleted successfully." : "Teams deleted successfully.",
        "Team name should not be empty." : "Team name should not be empty.",
        "Please enter valid team name." : "Please enter valid team name.",
        "Do you want to delete the selected teams?" : "Do you want to delete the selected teams?",
        "The selected teams deleted." : "The selected teams deleted.",
        "The selected team status changed." : "The selected team status changed.",
        "Do you want to change" : "Do you want to change ",
        "the selected teams" : "the selected teams",
        "status?" : " status?",
        "Yes, Change!" : "Yes, Change!",
        "Team successfully created" : "Team successfully created",
        // manage user page 
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phoneNumber: "Phone Number",
        role: "Role",
        loadMore: "Load More",
        addUser: "Add User",
        updateUser: "Update User",
        "User updated successfully" : "User updated successfully",
        "User added successfully": "User added successfully",
        "user state changed to" : "user state changed to",
        "user deleted.": "user deleted.",
        "Do you want to delete the selected users?" : "Do you want to delete the selected users?",
        "The selected users deleted." : "The selected users deleted.",
        "Unable to process your request" : "Unable to process your request",
        "Do you want to change the selected users status from" : "Do you want to change the selected users status from",
        "The selected users status changed from" : "The selected users status changed from",
        "User successfully created." : "User successfully created.",
        "Error:" : "Error:",
        "Ok" : "Ok",
        "Do you want to delete" : "Do you want to delete ",
        "First name should not be empty." : "First name should not be empty.",
        "Please enter valid first name." : "Please enter valid first name.",
        "Last name should not be empty." : "Last name should not be empty.",
        "Email should not be empty." : "Email should not be empty.",
        "Please enter valid email." : "Please enter valid email.",
        "Please select user role." : "Please select user role.",
        "Email already exist in our portal" : "Email already exist in our portal",
        "accountExitsError" : "An account with the given email already exists.",
        "Team" : "Team",
        "Import CSV" : "Import CSV",
        "Import Users" : "Import Users",
        "Created date" : "Created date",
        "Status change date" : "Status change date",
        "Status" : "Status",
        "Filtered users" : "Filtered users",
        "All users" : "All users",
        "Active users" : "Active users",
        "Inactive users" : "Inactive users",
        "Pending users" : "Pending users",
        "New users" : "New users",
        
        // table page
        noUsersFound: "No user found",
        userEmail: "Email",
        // tab page
        manageTeams: "Manage Teams",
        manageEnterprises: "Manage Enterprises",
        manageUsers: "Manage Users",
        // users logs 
        "Users Logs" : "Users Logs",
        "Search" : "Search",
        "Table" : "Table",
        // Process CSV 
        "Error" : "Error",
        "Error while creating users error log for more details" : "Error while creating users error log for more details",
        "Download Error CSV" : "Download Error CSV",
        "Clear" : "Clear",
        "Save" : "Save",
        "Import" : "Import",
        "Users created :" : "Users created : ",
        "Select" : "Select",
        "Please select valid CSV file format" : "Please select valid CSV file format",
        "Search by username" : "Search by username"
      }
    },
    de: { // German
      translations: {
        manageTeams: "TEAMS VERWALTEN",
        manageEnterprises: "ENTERPRISES VERWALTEN",
        manageUsers: "BENUTZER VERWALTEN"
      }
    },
    fr: { // French
      translations: {
        manageTeams: "GÉRER LES ÉQUIPES",
        manageEnterprises: "GÉRER LES ENTREPRISES",
        manageUsers: "GÉRER LES UTILISATEURS"
      }
    }
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
