const SWITCH_USER = 'SWITCH_USER';
const ADD_USER = 'ADD_USER';
const UPDATE_USER = 'UPDATE_USER';
const DELETE_USER = 'DELETE_USER';

function switchUser(user) {  
  return {
    type: SWITCH_USER,
    user
  }
}

function addUser(profile) {
  return {
    type: ADD_USER,
    profile
  }
}

function updateUser(profile) {
  return {
    type: UPDATE_USER,
    profile
  }
}

// when user sign out
function deleteUser() {
  return { type: DELETE_USER }
}

export { SWITCH_USER, ADD_USER, UPDATE_USER, DELETE_USER }
export { switchUser, addUser, updateUser, deleteUser }