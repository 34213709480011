import styles from '../scss/modules/loader.module.scss';

import React, { Component } from 'react'
import { render, unmountComponentAtNode } from 'react-dom'

export default class HMILoader extends Component {
  
  close = () => {
    removeElementLoader()
  }

  render () {
    const { message } = this.props

    return (
        <div className={styles.appLoading}>
                <div className={styles.hmiLoadingSpinner}></div>
                {message && <p>{message}</p>}
        </div>     
    )
  }
}


function createElementLoader (properties) {
  let loaderTarget = document.body; 
  if(properties) {
   loaderTarget = document.getElementById(properties.target)
  }
    let divTarget = document.createElement('div')
    divTarget.id = 'hmi-loader-wrapper';
    if(!properties)
      divTarget.classList.add('page-loader');
    loaderTarget.appendChild(divTarget)
    render(<HMILoader {...properties} />, divTarget)
}

function removeElementLoader () {
  const target = document.getElementById('hmi-loader-wrapper')
  if(target) {
    unmountComponentAtNode(target)
    target.parentNode.removeChild(target)
  }
}


export function showLoader (properties) {
    createElementLoader(properties)
}
export function hideLoader() {
    removeElementLoader();
}