import React from 'react'
import logo from '../assets/img/logo-hmi-white.png';

const Logo = (props => {
    return (
        <div className="Login_logo_sm d-block d-sm-none" style={{ textAlign: 'center' }}>
            <img className="Login_img_sm_width" src={logo} alt="" />
        </div>
    )
})

export default Logo;