import React, { Component } from 'react'
import {connect} from 'react-redux';
import { CSVLink } from "react-csv";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import * as Groups from "../../../utils/roledropdown";
import { Trans, withI18n } from "react-i18next";
import moment from 'moment';
import { API } from 'aws-amplify';
import signedin from '../../../store/appdata/signedinUser';

class ExportUsers extends Component {
    constructor() {
        super();
        this.state = {
            dataToDownload: [],
            exportUserSelected: 'all'
        }
    }

    exportUsers = async (cb) => {
        //this.props.loadingPageStatusChange(true);
        if (this.state.exportUserSelected === 'filtered') {
            const users = await this.filteredRequest();
            this.afterComplete(users, cb);
        } else {
            let KeyConditionExpression = 'userStatus = :status';
            let params = {
                TableName: this.props.usertable,
            };
            if (this.state.exportUserSelected !== 'all') {
                params['IndexName'] = 'status-name';
                params['KeyConditionExpression'] = KeyConditionExpression;
                params['ExpressionAttributeValues'] = { ":status": this.state.exportUserSelected };
            }
            signedin.profile.docClient[this.state.exportUserSelected === 'all' ? 'scan' : 'query'](params, (err, data) => {
                this.afterComplete(data.Items, cb);
            });
        }
    }

    afterComplete = (data, cb) => {
        //this.props.loadingPageStatusChange(false);
        this.setState({ dataToDownload: this.formatUsersData(data), exportUserSelected: 'all' }, () => {
            cb();
        });
    }

    formatUsersData = (users) => {
        const updatedUsers = users.reduce((acc, user) => {
           if(user.email) {
                const userInfo = {};             
                userInfo['firstName'] = user.userInfo.firstName;
                userInfo['lastName'] = user.userInfo.lastName;                
                userInfo['email'] = user.email;
                userInfo['userRole'] = Groups.roles[+user.userRole];
                userInfo['userStatus'] = user.userStatus;
                userInfo['createdDate'] = user.createdAt ? moment.utc(user.createdAt).local().format("YYYY/MM/DD HH:mm") : '';
                userInfo['statusChangedDate'] = user.userInfo && user.userInfo.updatedAt ? moment.utc(user.userInfo.updatedAt).local().format("YYYY/MM/DD HH:mm") : '';
                
                if (user.enterpriseId) {
                    userInfo['enterprise'] = user.userInfo.enterprise;
                }
                if (user.teamId) {
                    userInfo['team'] = user.userInfo.team;
                }
                acc.push(userInfo);
            }

            return acc;
        }, []);
        return updatedUsers;
    }

    radioButtonPressed = (event) => {
        this.setState({
            exportUserSelected: event.target.value
        });
    }

    noRecord = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <p>{this.props.t('No record found')}</p>
                        <div className="justify-content-center d-flex">
                            <button className="btn btn-secondary export-button" onClick={() => {
                                onClose();
                            }} ref={(c) => c && c.focus()}>{this.props.t('Close')}</button>
                        </div>
                    </div>
                )
            }
        })
    }

    checkUserFilterisnull = () => {
        if (this.props.filtered) {
            return <div className="custom-control custom-radio checkbox-custom3">
                <input type="radio" name="export" id="filtered" className="custom-control-input" value='filtered' defaultChecked={this.state.exportUserSelected === "filtered"} onChange={this.radioButtonPressed} />
                <label className="custom-control-label" htmlFor="filtered">{this.props.t('Filtered users')}</label>
            </div>;
        }
    }

    download = (event) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui text-left'>
                        <h3 className="export-title">{this.props.t('Select users to export')}</h3>
                        <form>
                            <div className="mb-3">
                                {this.checkUserFilterisnull()}
                                <div className="custom-control custom-radio pb-c">
                                    <input type="radio" name="export" id="all" className="custom-control-input" value='all' defaultChecked={this.state.exportUserSelected === "all"} onChange={this.radioButtonPressed} />
                                    <label className="custom-control-label" htmlFor="all">{this.props.t('All users')}</label>
                                </div>
                                <div className="custom-control custom-radio pb-c">
                                    <input type="radio" name="export" id="active" className="custom-control-input" value='active' onChange={this.radioButtonPressed} />
                                    <label className="custom-control-label" htmlFor="active">{this.props.t('Active users')}</label>
                                </div>
                                <div className="custom-control custom-radio pb-c">
                                    <input type="radio" name="export" id="inactive" className="custom-control-input" value='inactive' onChange={this.radioButtonPressed} />
                                    <label className="custom-control-label" htmlFor="inactive">{this.props.t('Inactive users')}</label>
                                </div>
                                <div className="custom-control custom-radio pb-c">
                                    <input type="radio" name="export" id="pending" className="custom-control-input" value='pending' onChange={this.radioButtonPressed} />
                                    <label className="custom-control-label" htmlFor="pending">{this.props.t('Pending users')}</label>
                                </div>
                                <div className="custom-control custom-radio pb-c">
                                    <input type="radio" name="export" id="new" className="custom-control-input" value='new' onChange={this.radioButtonPressed} />
                                    <label className="custom-control-label" htmlFor="new">{this.props.t('New users')}</label>
                                </div>
                            </div>
                        </form>
                        <div className="justify-content-end d-flex">
                            <button className="btn btn-custom export-button" ref={(c) => c && c.focus()} type="button" onClick={() => {
                                this.exportUsers(() => {
                                    if (this.state.dataToDownload.length > 0)
                                        this.csvLink.link.click();
                                    else
                                        this.noRecord();
                                });
                                onClose();
                            }}>{this.props.t('Export')}</button>
                            <button className="btn btn-secondary export-button" onClick={() => {
                                this.setState({
                                    exportUserSelected: 'all'
                                });
                                onClose();
                            }}>{this.props.t('Cancel')}</button>
                        </div>
                    </div>
                )
            }
        })
    }

    filteredRequest = () => {
        const  query  = {...this.props.query};
     
        const promise = new Promise((resolve, reject) => {
            query.pagination.pagenumber = 1;
            query.pagination.size = 200;
            let filterQuery = {};
            for (let key in query) {
                if (query.hasOwnProperty(key)) {
                    const value = (query[key] && typeof  query[key]) === 'object' ? JSON.stringify(query[key]) : query[key];
                    filterQuery[key] = value;
                }
              }

            const params= {
                ...filterQuery,
                userRole: signedin.profile.userRole,
                from: 0,
            }  
            
            API.get('elasticsearchhmi', '/search/filter', {
                queryStringParameters: params
            }).then(res => {
                let retData = res.data.hits.hits;
                const usersList = retData.map(source => source._source);
                resolve(usersList);
            }).catch(error => {
                reject([]);
            });
        });
        return promise;
    }

    render() {
        return <div>
            <CSVLink
                data={this.state.dataToDownload}
                filename="HMIusers.csv"
                className="hidden"
                ref={(r) => this.csvLink = r}
                target='_self' />
            <div>
                <button className="btn btn-secondary mr-3" onClick={this.download}><Trans>Export Users</Trans></button>
            </div>
        </div>
    };
}

const mapStateToProps = (state) => {
    const {users} = state;
    return ({
       query: users.query,
       filtered: users.filtered
       })
    }
  export default connect(mapStateToProps)(withI18n()(ExportUsers));


