import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import logininfo from '../../assets/img/login-img.png';
import logo from '../../assets/img/logo-hmi-white.png';
import Logo from '../../components/logo';
import '../signin/signin.scss';
import { ClipLoader } from 'react-spinners';
import Alert from 'react-s-alert';
import { Trans, withI18n } from "react-i18next";
import {passwordValidate, passwordValidateReturn} from '../../utils/utilityfunctions';
import styles from '../../scss/modules/loader.module.scss';

const initState = () => {
    return {
         code: '',
         newpassword: '',
         confirmpassword: '',
         valid: true,
         loading: false,
         active: 'generate',
         code_status: <Trans>Verification code sent to email.</Trans>,
         error_code_message: <Trans>Verificationcodeerror</Trans>,
         error_code_valid: true,
         error_user_message: <Trans>Username can't be empty</Trans>,         
         error_user_valid: true,
         error_newpassword_valid: true,
         error_confirmpassword_valid: true,
         cognito_error: ''
     }
 }
class ForgotPassword extends Component {

    state = {
        username: null,
        ...initState()
    }
 
    inputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
            cognito_error: ''
        });
    }

    codeChange = (event) => {        
        this.setState({
            code: event.target.value,
            cognito_error: ''                    
        });
    }

    codeFocus = (event) => {
        this.setState({
            error_code_valid: true                       
        });
    }

    codeBlur = (event) => {
        const code = event.target.value;
        let error_code_valid = true;
        if(!/^[0-9]+$/.test(code)) {
            error_code_valid = false;
        }
        this.setState({
            error_code_valid                       
        });
    }
    confirmpasswordChange = (event) => {
        const pswd = event.target.value;
        this.setState({
            confirmpassword: pswd,
            error_confirmpassword_valid: true
        });
    }

    passwordChange = (event) => {
        const pswd = event.target.value;
        this.setState({
            newpassword: pswd,
        });
        passwordValidate(pswd);
    }

    resendverificationcode = (e) => {
        e.preventDefault();
        this.resetState();
        this.sendverificationcode(e);
    }

    sendverificationcode = (event) => {
        event.preventDefault();
        if (!this.state.username) {
            this.setState({ error_user_message: <Trans>Username can't be empty</Trans>, error_user_valid: false })
            return;
        }
        this.setState({ loading: true, code_status: 'Code sending...' });
        let username = this.state.username;
        username = username.toLowerCase();
        Auth.forgotPassword(username)
            .then(data => {
                this.setState({ loading: false, code_status: <Trans>Verification code sent to email.</Trans> });
                document.querySelector('.Login').classList.add('oveflow-hidden');
                setTimeout(() => {
                    this.setState({ active: 'verify' });
                    document.querySelector('.Login_division').classList.add('verify');
                    setTimeout(() => {
                        document.querySelector('.Login').classList.remove('oveflow-hidden');
                    }, 400)
                }, 400)
            })
            .catch(err => {
                this.setState({ error_user_message: err.message, code_status: err.message, error_user_valid: false, loading: false })
            });
    }

    verifycode = (e) => {
        e.preventDefault();
        let { username, code, newpassword, confirmpassword } = this.state;          
        let valid = true;
        let error_code_valid = true;
        let error_newpassword_valid= true;
        let error_confirmpassword_valid= true;
        if (!/^[0-9]+$/.test(code)) {
            error_code_valid = false;          
            valid = false;
        }
      
        if (!passwordValidateReturn(newpassword)) {
            valid = false;
            error_newpassword_valid= false;
        }

        if (newpassword && newpassword !== confirmpassword) {
            valid = false;
            error_confirmpassword_valid= false;

        }
        if (!valid) {
            this.setState({
                error_code_valid,
                error_newpassword_valid,
                error_confirmpassword_valid
            })
            return;
        } 
        this.setState({ loading: true });
        username = username.toLowerCase();
        Auth.forgotPasswordSubmit(username, code, newpassword)
            .then(data => {
                Alert.success(this.props.t('Password Successfully changed.'), {
                    position: 'top-right',
                    effect: 'stackslide',
                    onClose: () => {
                        this.setState({ loading: false }, () => {
                            this.props.history.replace('/signIn');
                        });                     
                    }
                });                            
            })
            .catch(err => {
                this.setState({ loading: false, cognito_error:err.message, error_code: true });
            });
    }
    validationInfo = (show) => {
        this.setState({
            error_newpassword_valid: show
        });
        if (!show && this.state.newpassword) {
            setTimeout(() => {
                passwordValidate(this.state.newpassword);
            }, 10)
        }
    }
    editusername = (e) => {
        e.preventDefault();
        this.resetState();
        document.querySelector('.Login_division').classList.remove('verify');
    }
    resetState = () => {
        this.setState({...initState()});
    }
   
    render() {
        let loaderClass = '';
        if (this.state.loading) {
            loaderClass = 'loading';
        }
        const { t, profile, authProgress } = this.props;
        return (
                !authProgress ? (
                    !profile ? (
                        <div className="Login_container">
                            <ClipLoader
                                className="clipLOader"
                                sizeUnit={"px"}
                                size={50}
                                color={'#fff'}
                                loading={this.state.loading}                                
                            />                      
                            <div className={"Login " + loaderClass}>
                                <div className="d-none d-sm-block">
                                    <img className="Login_info" src={logininfo} alt="" />
                                    <div className="Login_logo">
                                        <img className="Logo" src={logo} alt="" />
                                    </div>
                                </div>

                                <div className="Login_division">
                                    {this.state.active === 'generate' && <div className={this.state.active === 'generate' ? 'active-box Login_form' : 'Login_form hidden'}>
                                        <Logo />
                                        <h1><Trans>forgotPassword</Trans></h1>
                                        <form onSubmit={this.sendverificationcode.bind(this)}>
                                            <p className="form-message">
                                                <Trans>forgotPasswordErrorMessage</Trans>
                                            </p>
                                            {!this.state.error_user_valid && (
                                                <p className="Login_error form-info">
                                                    {this.state.error_user_message}
                                                </p>
                                            )}
                                            <div className="form-group">
                                                <input className="form-control" type="text" name="username" placeholder={t('userName')} onChange={this.inputChange.bind(this)} />
                                            </div>
                                            <div>
                                                <button type="submit" className="btn"><Trans>generateVerificationCode</Trans></button>
                                            </div>
                                        </form>
                                    </div>}

                                    {this.state.active === 'verify' && <div className={this.state.active === 'verify' ? 'active-box Login_form' : 'hidden Login_form'}>
                                        <Logo />
                                        <h1 className="mb-1"><Trans>forgotPassword</Trans></h1>

                                        <form onSubmit={this.verifycode.bind(this)}>
                                            <p><b>{this.state.username}</b>
                                                <button className="btn btn-link p-0 pl-2" type="button" tabIndex="-1" onClick={this.editusername.bind(this)}><Trans>edit</Trans></button></p>

                                            <p className="form-info success">
                                                {this.state.code_status}
                                            </p>
                                            {this.state.cognito_error &&(                                                
                                                <p className="Login_error form-info">{this.state.cognito_error}</p>                                                
                                            )}                                            

                                            <div className="form-group position-relative error-box">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="code"
                                                    placeholder={t('enterCode')}
                                                    onChange={this.codeChange} 
                                                    onBlur={this.codeBlur}
                                                    onFocus={this.codeFocus}
                                                    maxLength="10"
                                                    />
                                                <p className="text-right">
                                                    <button type="button" className="btn btn-link p-0" tabIndex="-1" onClick={this.resendverificationcode.bind(this)}><Trans>resendCode</Trans></button>
                                                </p>
                                                {!this.state.error_code_valid &&
                                                    (
                                                        <div className="error-info error">
                                                            <p className="mb-0">{this.state.error_code_message}</p>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="form-group position-relative info-box">
                                                <input className="form-control"
                                                    type="password"
                                                    name="newpassword"
                                                    placeholder={t('password')}
                                                    onBlur={() => { this.validationInfo(true) }}
                                                    onFocus={() => { this.validationInfo(false) }}
                                                    onChange={this.passwordChange.bind(this)}
                                                    maxLength="50"
                                                    />

                                                {!this.state.error_newpassword_valid && (
                                                        <div id="pswd_info" className="error-info">
                                                            <h4><Trans>passwordMustMeettheFollwingRequirements</Trans></h4>
                                                            <ul>
                                                                <li id="letter" className="invalid"><Trans>At least</Trans><strong><Trans>one lowercase letter</Trans></strong></li>
                                                                <li id="number" className="invalid"><Trans>At least</Trans><strong><Trans>one number</Trans></strong></li>
                                                                <li id="length" className="invalid"><Trans>Be at least</Trans><strong><Trans>8 characters</Trans></strong></li>
                                                            </ul>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="form-group position-relative error-box">
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    name="confirmpassword"
                                                    placeholder={t('confirmPassword')}
                                                    onChange={this.confirmpasswordChange.bind(this)}                                                    
                                                    />

                                                {!this.state.error_confirmpassword_valid &&
                                                    (
                                                        <div className="error-info error">
                                                            <p className="mb-0"><Trans>passwordsDoesNotMatch</Trans></p>
                                                        </div>
                                                    )}
                                            </div>
                                            <div>
                                                <button type="submit" className="btn"><Trans>submit</Trans></button>
                                            </div>
                                        </form>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    ) : (
                            <Redirect to={{
                                pathname: '/',
                                state: { from: this.props.location }
                            }}
                            />
                        )
            ): 
            <div className={styles.appLoading}>
                <div className={styles.hmiLoadingSpinner}></div>
            </div>       
            )
    }
}

export default withI18n()(ForgotPassword);