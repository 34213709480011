import { createContext } from "react";

const authContext = createContext({
  authenticated: false, 
  user: {},
  cred: {},
  accessToken: "",
  setSession: () => {},
  handleAuthentication: () => {},
  setChangePasswordSession: () => {},  
  logout: () => {} 
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;