export function checkValidity(value, rules) {
    let isValid = {
      status: true,
      key: ''
    };
      
       if (!rules) {
            return isValid;
        }
        if (rules.isRequired && isValid.status) {
            isValid = value ? isValid :  {status: false, key: 'isRequired'};
        }
        
        if (rules.isSpecCharacters && isValid.status) {          
          let pattern = /^[A-Za-zΑ0-9.-_\u00C0-\u00FF\s]+$/g;          
          isValid =  pattern.test(value) ? isValid :  {status: false, key: 'isSpecCharacters'};
        }    

        if (rules.isCharacters && isValid.status) {           
           let pattern = /^[A-Za-zΑ0-9._\-\u00C0-\u00FF\s]+$/g;           
           isValid =  pattern.test(value) ? isValid :  {status: false, key: 'isCharacters'};
         }   
        
        if (rules.isaddressCheck && isValid.status) {
          let pattern = /^[a-zA-Z0-9]{1}/g
          isValid =  pattern.test(value) ? isValid :  {status: false, key: 'isaddressCheck'};
        }  

        if (rules.minLength) {
            isValid = value.length >= rules.minLength ? isValid :  {status: false, key: 'minLength'};
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail && isValid.status) {
            const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
            isValid = pattern.test(value) ? isValid :  {status: false, key: 'isEmail'};
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }
        return isValid;
  }

  export function validateOnsubmit(updatedForm) {     
    for (let elementIdentifier in updatedForm) {        
      updatedForm[elementIdentifier].touched = true;    
      }  
  return updatedForm;
  }