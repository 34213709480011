import React, { Component } from 'react'
import { Trans } from "react-i18next";
import {connect} from 'react-redux';
import * as Groups from "../../../utils/roledropdown";
import Alert from 'react-s-alert';
import { CSVLink } from "react-csv";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { iteratorloop, validateEmail, writeUserActivityLog, removeHtmlfromString, saveJSONasString } from '../../../utils/utilityfunctions';
import moment from 'moment';
import { omit } from 'lodash';
import { fetchUsers } from "../../../store/appdata/user/fetchActions";
import { showLoader, hideLoader } from "../../../components/loadingComponent";
import { ENVIRONMENTS } from '../../../environment';
import signedin from '../../../store/appdata/signedinUser';

const uuidv1 = require('uuid/v1');

class ProcessCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            errors: [],
            file: '',
            fileName: '',
            progress: false,
            successCount: 0
        };
        this.poolid = 'us-east-2_YxLjMP78G';
        this.usertable = ENVIRONMENTS.dynamoDB['users'];
        this.enterprise_table = ENVIRONMENTS.dynamoDB['enterprises'];
        this.team_table = ENVIRONMENTS.dynamoDB['teams'];
        this.roles = {
            superadmin: 'SuperAdmin',
            systemadmin: 'SystemAdmin',
            enterpriseadmin: 'EnterpriseAdmin',
            teamadmin: 'TeamAdmin',
            champion: 'Champion',
            user: 'User',
        }
    }

    csvToJson = csv => {
        let lines = csv.split("\n");
        lines.shift();
        //lines.pop();
        const headers = ['firstName', 'lastName', 'userRole', 'email', 'enterpriseName', 'teamName'];

        const result = lines.reduce((accumulator, line) => {
            const valExists = line.replace(/,/g, '').trim();
            if (valExists) {
                let obj = {};
                const currentline = line.split(",");
                headers.forEach((header, indexHeader) => {
                    if (header !== 'userRole') {
                        obj[header] = currentline[indexHeader];
                    } else {
                        let role = (currentline[indexHeader] + '').toLowerCase().replace(/ /g, '');
                        if (role === '')
                            obj[header] = '';
                        else {
                            obj[header] = this.roles[role];
                            obj['originalRole'] = currentline[indexHeader];
                        }
                    }
                });
                accumulator.push(obj);
            }
            return accumulator;
        }, []);
        return result;
    }

    fileChanges = (event) => {
        const file = event.target.files[0];
        const fileName = (event.target.files[0].name).toLowerCase();
        if (fileName.split('.').pop() !== 'csv') {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <p><Trans>Please select valid CSV file format</Trans></p>
                            <button onClick={onClose}><Trans>Ok</Trans></button>
                        </div>
                    )
                }
            })
            return;
        }
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = this.csvToJson(event.target.result);
            this.setState({
                users: [...data],
                file: event.target.value,
                fileName: fileName,
                errors: [],
                successCount: 0
            });
        };
        reader.readAsText(file, 'ISO-8859-1');
    } // //'macintosh')// 'ISO-8859-1');

    findEnterprise = (userData, callback) => {
        let params = {};
        if (userData.enterpriseName && userData.enterpriseName.trim()) {
            params = {
                TableName: this.enterprise_table,
                IndexName: 'estatus-index',
                KeyConditionExpression: 'eStatus = :s and eName= :n',
                ExpressionAttributeValues: { ':s': 'active', ':n': (userData.enterpriseName.toLowerCase() + '').trim() }
            };
        }
        else
            return callback(null, null);

        signedin.profile.docClient.query(params, (err, data) => {
            if (err) {
                return callback(null, err);
            } else {
                if (data.Items.length === 1)
                    return callback(data.Items[0], null);
                else if (data.Items.length === 0)
                    return callback(null, "Error: Enterprise name not found");
                else if (data.Items.length > 1)
                    return callback(null, "Error: Please input valid enterprise name");
            }
        });
    }

    findTeam(userData, callback) {
        var params = {};
        if (userData.teamName && userData.teamName.trim()) {
            params = {
                TableName: this.team_table,
                IndexName: 'tstatus-index',
                KeyConditionExpression: 'tStatus = :s and tName= :n',
                ExpressionAttributeValues: { ':s': 'active', ':n': (userData.teamName.toLowerCase() + '').trim() }
            }
        } else
            return callback(null, null);

        signedin.profile.docClient.query(params, (err, data) => {
            if (err) {
                return callback(null, err);
            } else {
                if (data.Items.length === 1)
                    return callback(data.Items[0], null);
                else if (data.Items.length === 0)
                    return callback(null, "Error: Team name not found");
                else if (data.Items.length > 1)
                    return callback(null, "Error: Please input valid team name");
            }
        });
    }

    findEmail(userData, callback) {
        var params = {};
        params = {
            TableName: this.usertable,
            IndexName: 'email-index',
            KeyConditionExpression: 'email = :e',
            ExpressionAttributeValues: {
                ":e": userData.email.toLowerCase().trim()
            }
        }

        signedin.profile.docClient.query(params, (err, data) => {
            if (err) {
                return callback(err);
            } else {
                if (data.Items.length === 0)
                    return callback(null);
                else if (data.Items.length > 0)
                    return callback("Error: Email already exists");
            }
        });
    }

    checkingFileMandatoryFieldforSingle(userData) {
        let error = '';
        if (!userData.firstName)
            error = "Error : First name not found";
        if (!userData.lastName) {
            if (error !== '')
                error += ', ';
            error += "Error : Last name not found";
        }
        if (!userData.email) {
            if (error !== '')
                error += ', ';
            error += "Error : Email not found";
        }

        if (userData.email) {
            if (error !== '')
                error += ', ';
            if (!validateEmail(userData.email))
                error += "Error : Email is not valid";
        }

        if (userData.userRole === undefined) {
            if (error !== '')
                error += ', ';
            error += "Error : User role not found";
        }
        return error;
    }

    endofUpload = (updated, failed) => {
        let errCount = failed.length;
        let alertmessage = 'CSV imported successfully.';
        let alertType = 'success';
        if (errCount > 0) {
            alertmessage = "CSV imported with errors, Please check.";
            alertType = 'warning';
        }
        Alert[alertType](alertmessage, {
            position: 'top-right',
            effect: 'stackslide',
        });
        let userImportedData = updated.concat(failed);
        userImportedData = userImportedData.reduce((acc, user) => {
        
            acc.push({ email: user.email ?  user.email :  user.userInfo.email, status: user.error ? removeHtmlfromString(user.error) : 'Done' })
            return acc;
        
        }, []);

        writeUserActivityLog('Users created via CSV import : ' + saveJSONasString(userImportedData), 'user');
        this.setState({
            fileName: '',
            errors: failed.map(f => omit(f, 'originalRole')),
            progress: false,
            successCount: updated.length
        });
        hideLoader();
        this.props.fetchUsers();
    }

    createUsers = () => {
        //this.props.loadingPageStatusChange(true);
        let changeUserData = [...this.state.users];
        this.setState({
            progress: true,
        });
        const that = this;
        showLoader();
        function* iteratorHandler() {
            for (let i = 0; i < changeUserData.length; i++) {
                yield createUser({ ...changeUserData[i] }, i);
            }
        }
        const iterator = iteratorHandler();

        iteratorloop(iterator, this.endofUpload);

        function createUser(userData, index) {
            return new Promise((resolve, reject) => {
                let importError = that.checkingFileMandatoryFieldforSingle(userData);
                if (importError) {
                    userData.error = importError;
                    if (userData.originalRole) {
                        userData.userRole = userData.originalRole;
                        delete userData.originalRole;
                    }
                    let returnData = {
                        status: false,
                        item: { ...userData }
                    }
                    resolve(returnData);
                }
                else {
                    let dontShowRoleinTable = false;
                    if (userData.userRole === '') {
                        dontShowRoleinTable = true;
                        userData.userRole = 'User';
                    }
                    const userRoleIndex = Groups.rolesIndex[userData.userRole].index;
                    const id = uuidv1();
                    const email = userData.email.toLowerCase().trim();
                    const created_time = moment().utc().format();

                    that.findEmail(userData, (emailFound) => {
                        if (emailFound) {
                            userData.error = emailFound;
                            if (dontShowRoleinTable)
                                userData.userRole = null;

                            let returnData = {
                                status: false,
                                item: { ...userData }
                            }
                            resolve(returnData);
                        } else {
                            if (userRoleIndex === 0) {
                                // console.log('Cognito flow ===', userData.email);
                                // Cognito flow 
                                that.createCognitoUser(userData, (returnData) => {
                                    if (returnData.status) {
                                        createUserinDB(`unconfirmed-${returnData.userName}`, returnData.userName);
                                    } else {
                                        resolve(returnData);
                                    }
                                });
                            } else {
                                // console.log('user flow ===', userData.email);
                                // user flow
                                createUserinDB(id, null);
                            }

                            function createUserinDB(id, userName) {
                                that.findEnterprise(userData, (enterprise, eerrFound) => {
                                    that.findTeam(userData, (team, terrFound) => {
                                        let account_data = {
                                            firstName: userData.firstName,
                                            lastName: userData.lastName,
                                            updatedAt: created_time
                                        };
                                        if (userName)
                                            account_data['userName'] = userName;
                                        let fullName = (userData.firstName + ' ' + userData.lastName).toLowerCase();
                                        let Item = {
                                            id: id,
                                            userInfo: account_data,
                                            userRole: userRoleIndex,
                                            fullName: fullName,
                                            email: email,
                                            userStatus: userRoleIndex === 0 ? 'active' : 'new',
                                            createdAt: created_time
                                        }
                                        if (enterprise) {
                                            Item['enterpriseId'] = enterprise.id;
                                            Item.userInfo.enterprise = enterprise.eInfo.name;
                                        }
                                        if (team) {
                                            Item['teamId'] = team.id;
                                            Item.userInfo.team = team.tInfo.name;
                                        }

                                        signedin.profile.docClient.put({
                                            TableName: that.usertable,
                                            Item: Item
                                        }, (err, dbdata) => {
                                            let successuserdata = {
                                                firstName: userData.firstName,
                                                lastName: userData.lastName,
                                                id: id,
                                                email: email,
                                                userRole: Groups.rolesIndex[userData.userRole].index,
                                                userStatus: Item.userStatus,
                                                createdAt: Item.createdAt
                                            }
                                            if (dontShowRoleinTable)
                                                userData['userRole'] = null;

                                            if (userData.originalRole)
                                                delete userData.originalRole;


                                            if (userName)
                                                successuserdata['userName'] = userName;

                                            userData.error = '<span style="color:#22a022">User account creation done.</span> ';
                                            if (eerrFound !== null) {
                                                userData.error += eerrFound ? eerrFound : '';
                                            }
                                            if (terrFound !== null) {
                                                if (eerrFound !== null)
                                                    userData.error += ',';
                                                userData.error += "  " + (terrFound ? terrFound : '');
                                            }
                                            if (eerrFound === null && terrFound === null)
                                                userData.error = 'Done';

                                            let returnData = {
                                                status: true,
                                                item: { ...userData },
                                                successData: Item
                                            }
                                            resolve(returnData);
                                        });
                                    });
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    createCognitoUser = (userData, callback) => {
        let params = {
            UserPoolId: this.poolid,
            Username: userData.email.trim(),
            DesiredDeliveryMediums: [
                'EMAIL'
            ],
            UserAttributes: [
                {
                    Name: 'email',
                    Value: userData.email.trim()
                },
                {
                    Name: 'email_verified',
                    Value: 'True'
                },
                {
                    Name: 'name',
                    Value: userData.firstName
                }
            ]
        };

        signedin.profile.cognitoidentityserviceprovider.adminCreateUser(params, (err, data) => {
            if (err) {
                userData.error = 'Error : ' + err;
                let returnData = {
                    status: false,
                    item: { ...userData }
                }
                callback(returnData);
            }
            else {
                var params = {
                    GroupName: userData.userRole,
                    UserPoolId: this.poolid,
                    Username: data.User.Username.trim()
                };
                signedin.profile.cognitoidentityserviceprovider.adminAddUserToGroup(params, (err, groupdata) => {
                    if (err) {
                        userData.error = 'Error : ' + err;
                        let returnData = {
                            status: false,
                            item: { ...userData }
                        }
                        callback(returnData);
                    }
                    else {
                        let returnData = {
                            status: true,
                            item: { ...userData },
                            userName: data.User.Username
                        }
                        callback(returnData);
                    }
                });
            }
        });
    }

    resetForm() {
        this.setState({
            file: '',
            users: [],
            errors: [],
            fileName: '',
            successCount: 0
        });
    }

    render() {
        return <React.Fragment>
            <div>
                <div className="signup-form">
                    <div className="input-group my-3" style={{ width: "50%" }}>
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" accept=".csv" onChange={this.fileChanges} value={this.state.file || ''} />
                            <label className="custom-file-label" style={{ zIndex: 0 }}>{this.state.fileName === '' ? 'Choose file ' : this.state.fileName}</label>
                        </div>
                        <div className="input-group-append">
                            {(this.state.fileName !== '') && <div className="d-flex ml-3">
                                <div><button type="button" className="btn btn-primary  mr-3" onClick={() => this.createUsers()} disabled={this.state.progress}><Trans>Import</Trans></button></div>
                                <div><button type="button" className="btn btn-secondary" onClick={() => this.resetForm()}><Trans>Clear</Trans></button></div>
                            </div>}
                        </div>
                    </div>
                    {this.state.successCount > 0 && <div className="d-flex">
                        <p className="alert alert-success py-1"><Trans>Users created :</Trans> {this.state.successCount}</p>
                    </div>}

                    {(this.state.errors.length > 0) &&
                        <React.Fragment>
                            <div className="d-flex">
                                <p className="alert alert-warning py-1"><Trans>Error while creating users error log for more details</Trans></p>
                                <div style={{ paddingBottom: '10px', textDecoration: 'underline', marginLeft: 'auto', marginRight: 0 }}>
                                    <CSVLink target='_self' data={this.state.errors} filename={"upload-errors.csv"}><Trans>Download Error CSV</Trans></CSVLink>
                                </div>
                            </div>

                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col"><Trans>firstName</Trans></th>
                                        <th scope="col"><Trans>lastName</Trans></th>
                                        <th scope="col"><Trans>role</Trans></th>
                                        <th scope="col"><Trans>userEmail</Trans></th>
                                        <th scope="col"><Trans>Enterprise</Trans></th>
                                        <th scope="col"><Trans>Team</Trans></th>
                                        <th scope="col"><Trans>Error</Trans> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.errors.map((user, index) => {
                                        let errorColor = { color: "rgb(252, 113, 105)" };
                                        return (
                                            <tr key={index} className={user.rowclass}>
                                                <td className="capitalize">
                                                    {user.firstName}
                                                </td>
                                                <td className="capitalize">
                                                    {user.lastName}
                                                </td>
                                                <td>
                                                    {user.userRole}
                                                </td>
                                                <td>
                                                    {user.email}

                                                </td>
                                                <td>
                                                    {user.enterpriseName}
                                                </td>
                                                <td>
                                                    {user.teamName}

                                                </td>
                                                <td style={errorColor} dangerouslySetInnerHTML={{ __html: user.error }}></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    };
}

//export default ProcessCSV;

const mapDispatchToProps = {
    fetchUsers
  };

export default connect(null, mapDispatchToProps)(ProcessCSV);

