import React from 'react'
import { Trans } from "react-i18next";
import Select from 'react-select';

function TableNav(props) {
    return (
        <div className="row no-gutters mt-3 mb-2 user-filter w-100">
        <Select
            value={props.filtered}
            onChange={props.filterItems}
            options={props.filterOptions}
            className="react-select react-select-custom-width-common-table"
            isSearchable={false}
            classNamePrefix="react-select"
        />
        {(props.checked.length > 1 || props.checked[0] === true) && (
            <React.Fragment>
                <div className="ml-3">
                    {props.status === 'active' ? (
                        <button onClick={() => { props.changeStatus('inactive') }} className="btn btn-yellow mr-3"><Trans>inactiveSelected</Trans></button>
                    ) : (
                        <button onClick={() => { props.changeStatus('active') }} className="btn btn-yellow mr-3"><Trans>activeSelected</Trans></button>
                    )}
                </div>
                    <div><button onClick={props.delete} className="btn btn-delete"><Trans>deleteSelected</Trans></button></div>              
            </React.Fragment>
        )}
        </div>
        )
}

export default TableNav;

