import React, {Component} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { confirmAlert } from 'react-confirm-alert';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import Navtab from '../../../components/navtab';
import TableNav from "./tableNav";
import UserAction from "./userActions";
import { ClipLoader } from 'react-spinners';
import { Trans, withI18n } from "react-i18next";
import {rolesIndex}  from "../../../utils/roledropdown";
import moment from 'moment'
import {connect} from 'react-redux';
import { fetchUsers, filterUsers } from "../../../store/appdata/user/fetchActions";
import { startUserEdit, deleteUsers, updateStatus } from "../../../store/appdata/user/updateActions";
import { showLoader, hideLoader } from "../../../components/loadingComponent";


function onAction(e, userInfo, callback) {
    if(e.target.tagName !== 'TD' && e.target.closest('.pointer')) {
        const targetEl = e.target.closest('.pointer');
        let action = targetEl.dataset.title;
       callback(userInfo, action);
    }
}

  let searchDelayTime = null;

  class Table extends Component {
        state = {
            searchStr: '',
            checkedItems: new Map(),
            totalSelected: 0
          }       
     
      searchKeyPress = (e) => {
        let value = e.target.value.trim().toLowerCase();
        if(e.keyCode === 13 && value){         
            clearTimeout(searchDelayTime);
            this.props.filterUsers({type: 'searchStr', value}); 
         }
      }
      componentDidUpdate = () => {
          const bodywrapper = document.querySelector('.body-wrapper');
          if(bodywrapper) {
              const scrollwidth = bodywrapper.offsetWidth - bodywrapper.clientWidth;
              document.querySelector('#fixed-header').style.paddingRight = scrollwidth+'px';
          }
      }
      scrollHeader = (e) => {
        document.getElementById('fixed-header').style.marginLeft = (-e.target.scrollLeft)+'px';
      }
      searchHandleChange = (e) => {              
        let val = e.target.value;       
        this.setState(
            { 
                searchStr: val,
                checkedItems: new Map(),
                totalSelected: 0
            }, () => {
                clearTimeout(searchDelayTime);
                let value = this.state.searchStr;
                    value = value.trim().toLowerCase();   
                    searchDelayTime = setTimeout(() => {
                        this.props.filterUsers({type: 'searchStr', value}); 
                }, 600)                                            
            });      
        }
     
      loadMoreUsers = () => {
        const loadmore = true;
        if(this.props.filtered) {
            this.props.filterUsers({loadmore});  
        } else {
            this.props.fetchUsers({loadmore});
        }
      }

      tableSort = (field) => {
          let {sort} = this.props.query;
            const order = sort && sort.order === 'asc' ? 'desc': 'asc';
            const value = {
                field,
                order
            }
            this.setState(
                {
                    checkedItems: new Map(),
                    totalSelected: 0
                }, () => {
                    this.props.filterUsers({type: 'sort', value});          
                }
            )   
      }

      resetFilter = (e) => {
        const resetIcon = e.currentTarget.querySelector('svg');       
        resetIcon.classList.add('reset-rotate');
        setTimeout(() => {
            resetIcon.classList.remove('reset-rotate');
        }, 1000);
        this.setState(
            { 
                searchStr: '' ,
                checkedItems: new Map(),
                totalSelected: 0
            }, () => {
            this.props.fetchUsers();
        });
    }

    dropdownFilter = (event, type) => {    
        this.setState(
            {
                checkedItems: new Map(),
                totalSelected: 0
            }, () => {
                this.props.filterUsers({type, value: event});
            }
        )        
    }

    userAction = (userid, action) => {  
        this[action](userid);       
    }

    editUser = (userid) => {
        let user = this.props.users.find(user => user.id === userid);        
        this.props.startUserEdit(user);
    }

    deleteUser = (userid) => {
        const user = this.props.users.find(user => user.id === userid)
        const data = {
            message: this.props.t('Do you want to delete') + ` ${user.email}?`,
            btnText: this.props.t('Yes, Delete!')                                
        }
       this.confirmPopUp( data, () => {
            this.props.deleteUsers([user]).then(() => {
                Alert.success(`${user.email} deleted.`, {
                    position: 'top-right',
                    effect: 'stackslide',                   
                });
            })
       })
    }

    deleteUsers = () => {
     
        const users = [ ...this.state.checkedItems.keys() ]
            .filter(id => id !== 'all')
            .map(id => this.props.users.find(user => user.id === id));
        
        const data = {
            message: this.props.t(`Do you want to delete the selected users?`),
            btnText: this.props.t('Yes, Delete!')        
        }
        this.confirmPopUp( data, () => {
            showLoader({target: 'user-table'});
            this.props.deleteUsers(users).then(() => {
                this.updateSuccess();
                Alert.success(this.props.t(`The selected users deleted.`), {
                    position: 'top-right',
                    effect: 'stackslide',                   
                });
            })
            .catch(error => {
                
            })
       })
    }

    updateStatus = (fromstatus, tostatus) => {
        const users = [ ...this.state.checkedItems.keys() ]
            .filter(id => id !== 'all')
            .map(id => this.props.users.find(user => user.id === id));
        const data = {
            message: `${this.props.t("Do you want to change the selected users status from")} ${fromstatus} ${this.props.t('to')} ${tostatus}?`,
            btnText: this.props.t('Yes, Change!')                
        }
        this.confirmPopUp( data, () => {
            showLoader({target: 'user-table'});
            this.props.updateStatus(users, tostatus).then(() => {
                this.updateSuccess();
                Alert.success(`${this.props.t("The selected users status changed from")} ${fromstatus} ${this.props.t('to')} ${tostatus}.`, {
                    position: 'top-right',
                    effect: 'stackslide',                   
                });
            })
            .catch(error => {
                
            })
       })

    }
    updateSuccess = () => {
        this.setState({
            checkedItems: new Map(),
            totalSelected: 0
        }, () => {
            hideLoader();
        })
    }

    warningMessage = (message = `You can select up to 25 records`) => {        
        const data = {
            message: message,
            btnText: 'Ok!',
            hidetitle: true,
            hidecancelbtn: true               
        }
        this.confirmPopUp( data);
    }

    confirmPopUp = (data, callback) => {
        
        confirmAlert({
            customUI: ({ onClose }) => {
              return (                 
                 <div className='custom-ui'>                                                 
                  {!data.hidetitle && 
                        <h1>{this.props.t('Are you sure?')}</h1>                
                    }
                  <p>{data.message}</p>
                  {!data.hidecancelbtn && 
                    <button onClick={() => {                                  
                        onClose();
                        }}>{this.props.t('No')}</button>
                  }
                  <button className="btn-delete" onClick={() => {
                     onClose();
                     if(callback)
                        callback();
                    }} ref={(c)=> c && c.focus()}>{data.btnText}</button>
                </div>               
              )
            }
          })

    }

    selectUsers = (e) => {
        const user = e.target.dataset.user;        
        const isChecked = e.target.checked;
        const checkedboxes = document.querySelectorAll('#usertable tbody input[type=checkbox]:checked');
     
        let userslength = this.props.users.length;
        let prevtems = null;
        if(user !== 'all') {               
            let currentrow = document.querySelector('.current');           
            if(currentrow) {
                --userslength; 
            }
            if((!isChecked && this.state.checkedItems.get('all')) || (checkedboxes.length === userslength)) {
                prevtems = new Map(this.state.checkedItems)
                prevtems.set('all', isChecked);
                prevtems.set(user, isChecked);                
            } else {        
                prevtems = new Map(this.state.checkedItems)   
                prevtems.set(user, isChecked);                        
            }            
        } else {
            prevtems = new Map();
            prevtems.set('all', isChecked);
          
            this.props.users.forEach(user => {                
               if(user.id !== this.props.profile.id)
                prevtems.set(user.id, isChecked);                            
            }); 
        }
        const totalSelected = ([...(prevtems.values())].filter(selected => selected)).length;
        if(totalSelected <= 25) {
            this.setState({ 
                checkedItems: prevtems,
                totalSelected: totalSelected
            })
        }else {
            this.warningMessage();
        }
    }

    render() {
        let props =  this.props;
        let { sort } = this.props.query;
        let sortIcon = 'sort';
        if(sort) {
            sortIcon = sort.order ==='asc' ? 'sort-alpha-down': 'sort-alpha-up';
        } 
        let tableclass = props.filterprogress ? 'table-wrapper HmiBasicTable-loading': 'table-wrapper';
        return (
            <div className="row no-gutters my-3">           
                <div className="nav-wrapper">
                    <Navtab tabs={ props.tabs } style={{backgroundColor: 'rgb(34, 41, 82)', color: '#fff'}} /> 
                    { (this.state.totalSelected > 0) && (
                        <p className="mb-0 selected-info">Selected records: {this.state.totalSelected}</p>               
                    )}                
                </div>
                <TableNav 
                    updateStatus={this.updateStatus}
                    deleteUsers={this.deleteUsers}
                    searchStr={this.state.searchStr}
                    searchKeyPress={this.searchKeyPress}
                    searchHandleChange={this.searchHandleChange}
                    resetFilter={this.resetFilter}
                    filterUser={this.dropdownFilter}
                    query={props.query}
                    checkedItems={this.state.checkedItems}                    
                    />
            
            {props.users && props.users.length > 0  ? (
                <div className={tableclass} id="user-table">
                    <div className="header-wrapper" id="fixed-header">
                    <table className="table table-bordered mb-0" style={{tableLayout:'fixed'}} id="usertable">
                    <colgroup>
                            <col width="50"></col>
                            <col width="150"></col>
                            <col width="150"></col>
                            <col width="150"></col>
                            <col width="300"></col>
                            <col width="150"></col>
                            <col width="150"></col>
                            <col width="175"></col>
                            <col width="180"></col>
                            <col width="100"></col>
                            <col width="200"></col>
                        </colgroup>

                        <thead>
                            <tr>
                            
                                <th scope="col" className="table-box">                            
                                    <div className="custom-control custom-checkbox">                           
                                    <input 
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="checkboxall"
                                        checked={this.state.checkedItems.get('all') ? this.state.checkedItems.get('all'): false}
                                        data-user="all"
                                        onChange={this.selectUsers} />
                                    <label className="custom-control-label" htmlFor="checkboxall"></label>
                                    </div>                              
                                </th>
                            
                            <th scope="col" onClick={() => this.tableSort('userInfo.firstName')}>
                            <Trans>firstName</Trans>                      
                                <span className="sort-icon">
                                <FontAwesomeIcon
                                    icon={sort && sort.field ==='userInfo.firstName' ? ['fas',  sortIcon] : ['fas', 'sort']}                  
                                    fixedWidth={false}
                                    size="1x"
                                />
                                </span>
                        
                            </th>
                            <th scope="col" onClick={() => this.tableSort('userInfo.lastName')}>
                            <Trans>lastName</Trans>                       
                                <span className="sort-icon">
                                <FontAwesomeIcon
                                    icon={sort && sort.field ==='userInfo.lastName' ? ['fas', sortIcon] : ['fas', 'sort']}                  
                                    fixedWidth={false}
                                    size="1x"
                                />
                                </span>                       
                            </th>
                            <th scope="col" onClick={() => this.tableSort('userRole')}>
                            <Trans>role</Trans>                      
                                <span className="sort-icon">
                                <FontAwesomeIcon
                                    icon={sort && sort.field ==='userRole' ? ['fas', sort.order ==='asc' ? 'sort-up': 'sort-down'] : ['fas', 'sort']}                  
                                    fixedWidth={false}
                                    size="1x"
                                />
                                </span>                      
                            </th>
                            <th scope="col" onClick={() => this.tableSort('userInfo.email')}>
                            <Trans>userEmail</Trans>                      
                                <span className="sort-icon">
                                <FontAwesomeIcon
                                    icon={sort && sort.field ==='userInfo.email' ? ['fas', sortIcon] : ['fas', 'sort']}                  
                                    fixedWidth={false}
                                    size="1x"
                                />
                                </span>                    
                            </th>
                            <th scope="col">
                            <Trans>Enterprise</Trans>                              
                            </th>                     

                            <th scope="col">
                                <Trans>Team</Trans>                                     
                            </th>   
                            <th scope="col" onClick={() => this.tableSort('createdAt')}>
                                <Trans>Created date</Trans>
                                <span className="sort-icon">
                                <FontAwesomeIcon
                                    icon={sort && sort.field ==='createdAt' ? ['fas', sort.order ==='asc' ? 'sort-up': 'sort-down'] : ['fas', 'sort']}                  
                                    fixedWidth={false}
                                    size="1x"
                                />
                                </span>                               
                            </th>  

                            <th scope="col" onClick={() => this.tableSort('userInfo.updatedAt')}>
                                <Trans>Status change date</Trans>
                                <span className="sort-icon">
                                <FontAwesomeIcon
                                    icon={sort && sort.field ==='userInfo.updatedAt' ? ['fas', sort.order ==='asc' ? 'sort-up': 'sort-down'] : ['fas', 'sort']}                  
                                    fixedWidth={false}
                                    size="1x"
                                />
                                </span>                               
                            </th>   
                            
                            <th scope="col">
                                <Trans>Status</Trans>                                   
                            </th>                                                    
                                <th scope="col"><Trans>Action</Trans></th>                                
                            </tr>
                            </thead>
                    </table>
                    </div>
                    <div className="body-wrapper" onScroll={this.scrollHeader}>
                    <table className="table table-bordered" style={{tableLayout:'fixed'}} id="usertable">
                        <colgroup>
                            <col width="50"></col>
                            <col width="150"></col>
                            <col width="150"></col>
                            <col width="150"></col>
                            <col width="300"></col>
                            <col width="150"></col>
                            <col width="150"></col>
                            <col width="175"></col>
                            <col width="180"></col>
                            <col width="100"></col>
                            <col width="200"></col>
                        </colgroup>

                        <tbody>
                            {props.users.map((item, index) =>  {
                              
                                let rowclass = (props.editedItem && item.id===props.editedItem.id) ? 'active ': '';
                                rowclass = item.id===props.removerow ? rowclass + 'remove': rowclass;
                                rowclass = item.id === props.profile.id ? rowclass + 'current' : rowclass;
                                return (                                                                    
                                <tr key={index} className={ rowclass }>                                   
                                    <th scope="row" className="table-box">                                
                                        {(item.id !== props.profile.id) && (
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input"
                                                checked={this.state.checkedItems.get(item.id) ? this.state.checkedItems.get(item.id): false} 
                                                data-user={item.id} 
                                                onChange={this.selectUsers} id={"checkbox"+item.id}
                                                />
                                            <label className="custom-control-label" htmlFor={"checkbox"+item.id}></label>
                                        </div>
                                        )}                                
                                    </th>                                    
                                <td>
                                    <div>{item.userInfo.firstName}</div>
                                </td>
                                <td>
                                    <div>{item.userInfo.lastName}</div>
                                </td>
                                <td>
                                    <div> { rolesIndex.byindex[item.userRole].name}</div>
                                </td>
                                <td>
                                    <div>{item.email}</div>
                                </td>
                                <td>
                                    <div>{item.userInfo.enterprise}</div>
                                </td>
                                <td>
                                    <div>{item.userInfo.team}</div>
                                </td>
                                <td>
                                    <div>{item.createdAt ? moment.utc(item.createdAt).local().format("YYYY/MM/DD HH:mm"): null}</div>
                                </td>
                                <td>
                                    <div>{item.userInfo.updatedAt ? moment.utc(item.userInfo.updatedAt).local().format("YYYY/MM/DD HH:mm"): null}</div>
                                </td>
                                <td>
                                    <div className="text-uppercase">{item.userStatus}</div>
                                </td>
                                <UserAction 
                                    userInfo={item}
                                    userAction={this.userAction}
                                    onAction={onAction}
                                />                                
                                </tr>                        
                            )} ) }                             
                        </tbody>
                        </table>

                        { (props.LastEvaluatedKey || props.showmorebtn) && (
                            <div className="text-center loadmorewrapper">
                                { props.loadmore ? 
                                    (                      
                                        <ClipLoader
                                            className="clipLoader"      
                                            sizeUnit={"px"}
                                            size={50}
                                            color={'#fff'}
                                            loading={props.loadmore}
                                        />  
                                    ): (  
                                        <button className="load-more btn" onClick={this.loadMoreUsers}>    
                                            <Trans>loadMore</Trans>
                                        </button>
                                    )}
                                </div>
                            )
                        }

                        </div>
                        </div>
            ): (
                <div className="w-100 text-center alert alert-custom">
                    {!props.loading ? (
                        <span><Trans>noUsersFound</Trans></span>
                    ): (
                        <span><Trans>Loading...</Trans></span>
                    )}
                
                </div>
            ) }
           
            </div>
        )
    }
}

const mapStateToProps = (state) => {
     const {users} = state;
    return ({
        users: users.items,
        query: users.query,
        loadmore: users.loadmoreinprogress,
        filtered: users.filtered,
        filterprogress: users.filterprogress,
        showmorebtn: users.showmorebtn,
        loading: users.loading,
        LastEvaluatedKey: users.LastEvaluatedKey,
        tableLoading: users.tableLoading,
        editedItem: users.editedItem
        })
}

const mapDispatchToProps = {
    fetchUsers,
    filterUsers,
    startUserEdit,
    deleteUsers,
    updateStatus 
  };

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(Table));