import moment from 'moment';
import { ENVIRONMENTS }  from '../environment';
import signedin from '../store/appdata/signedinUser';

const uuidv1 = require('uuid/v1');

let intervalId;
export function isInViewport(elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

function scrollStep() {
    if (window.pageYOffset === 0) {
        clearInterval(intervalId);
    }
    window.scroll(0, window.pageYOffset - 30);
}

export function scrollToTop() {
    intervalId = setInterval(scrollStep.bind(this), 16.66);
}

export function capitalizeString(string) {
    return string ? string.replace(/^\w/, c => c.toUpperCase()) : '';
}

export function getTableDataFromChecked(items) {
    let selectedUser = new Map(
        [...items]
            .filter(([k, v]) => {
                if (k === 'all') return false;
                return v;
            })
    );
    return Array.from(selectedUser.keys());
}

const isPromise = obj => Boolean(obj) && typeof obj.then === 'function';

export const iteratorloop = (iterator, callback, updated = [], failed = []) => {

    const iteration = iterator.next();
    const value = iteration.value;

    if (isPromise(value)) {
        value.then(val => {
            if (val.item.error !== 'Done') {
                failed.push(val.item);
            }
            if (val.status) {
                updated.push(val.successData);
            }
            iteratorloop(iterator, callback, updated, failed);
        });
    } else {
        callback(updated, failed);
    }
}

export const sliceArrayByBatches = (users, chunk_size = 25) => {
    let userBatches = users.map(function (e, i) {
        return i % chunk_size === 0 ? users.slice(i, i + chunk_size) : null;
    }).filter(function (e) { return e; });
    return userBatches;
}


export const removeDataTypesFromDynamoDBData = (o) => {
    let descriptors = ['L', 'M', 'N', 'S'];
    for (let d of descriptors) {
        if (o.hasOwnProperty(d)) {
            return o[d];
        }
    }

    Object.keys(o).forEach((k) => {
        for (let d of descriptors) {
            if (o[k].hasOwnProperty(d)) {
                o[k] = o[k][d];
            }
        }
        if (Array.isArray(o[k])) {
            o[k] = o[k].map(e => removeDataTypesFromDynamoDBData(e))
        } else if (typeof o[k] === 'object') {
            o[k] = removeDataTypesFromDynamoDBData(o[k])
        }
    });
    return o;
}


export function writeUserActivityLog(action, table) {
    let item = {
        email: signedin.profile.email,
        action: action,
        table: table,
        createdAt: moment().utc().valueOf(),//moment().utc().format(),
        id: uuidv1(),
        filedtype : 'sort'
    }
    signedin.profile.docClient.put({
        TableName: ENVIRONMENTS.dynamoDB['logs'],
        Item: item
    }, (err, dbdata) => { });
}

export function removeHtmlfromString(str) {
    return str.replace(/<(?:.|\n)*?>/gm, '');
}

export function saveJSONasString(json) {
    json = JSON.stringify(json);
    json.replace(/\\"/g, "\uFFFF"); //U+ FFFF
    json = json.replace(/"([^"]+)":/g, "$1:").replace(/\uFFFF/g, "\\\"");
    return json;
}

export function passwordValidateReturn(pswd) {
    if (pswd)
        return (!(pswd.length < 8) && !(pswd.match(/[a-z]/) == null) && !(pswd.match(/\d/) == null));
    return false;
}
export function passwordValidate(pswd) {
    const length = document.querySelector('#length');
    const letter = document.querySelector('#letter');
    const number = document.querySelector('#number');
    const pswd_info = document.querySelector('#pswd_info');
    if(pswd_info) {
        if (!pswd || pswd.length < 8) {
            length.classList.remove('valid');
            length.classList.add('invalid');
        } else {
            length.classList.remove('invalid');
            length.classList.add('valid');
        }

        if (pswd.match(/[a-z]/)) {
            letter.classList.remove('invalid'); letter.classList.add('valid');
        } else {
            letter.classList.remove('valid'); letter.classList.add('invalid');
        }
        //validate number
        if (pswd.match(/\d/)) {
            number.classList.remove('invalid'); number.classList.add('valid');
        } else {
            number.classList.remove('valid'); number.classList.add('invalid');
        }
    }
}


export function validateEmail(email) {
    var re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

