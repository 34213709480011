export const SuperAdmin = {
        SuperAdmin: 'Super Admin',
        SystemAdmin: 'System Admin',
        EnterpriseAdmin: 'Enterprise Admin',
        TeamAdmin: 'Team Admin',
        Champion: 'Champion',
        User: 'User'
}
export const SystemAdmin = {
    SystemAdmin: 'System Admin',
    EnterpriseAdmin: 'Enterprise Admin',
    TeamAdmin: 'Team Admin',
    Champion: 'Champion',
    User: 'User'
}
export const EnterpriseAdmin = {
    EnterpriseAdmin: 'Enterprise Admin',
    TeamAdmin: 'Team Admin',
    Champion: 'Champion',
    User: 'User'
}
export const TeamAdmin = {
    TeamAdmin: 'Team Admin',
    Champion: 'Champion',
    User: 'User'
}
export const Champion = {
    Champion: 'Champion',
    User: 'User'
}

export const rolesIndex = 
    {
    byindex: [
        {name:'Super Admin', key:'SuperAdmin'},
        {name:'System Admin', key:'SystemAdmin'}, 
        {name:'Enterprise Admin', key:'EnterpriseAdmin'},
        {name:'Team Admin', key:'TeamAdmin'},
        {name:'Champion', key:'Champion'}, 
        {name:'User', key:'User'}
    ],
    SuperAdmin: {index: 0},
    SystemAdmin: {index: 1, query: {gt: 1, lt:5 } },
    EnterpriseAdmin: {index: 2, query: {gt: 2, lt:5 } },
    TeamAdmin: {index: 3, query: {gt: 3, lt:5 } },
    Champion: {index: 4, query: {gt: 4, lt:5 } },
    User: {index: 5}
    }

export const roles = {
    0 : 'SuperAdmin',
    1 : 'SystemAdmin',
    2 : 'EnterpriseAdmin',
    3: 'TeamAdmin',
    4: 'Champion',
    5 : 'User'
}