export const STAGING = {
    dynamoDB: {
        users: 'tf-dev-hmi-user',
        teams: 'tf-dev-hmi-team-table',
        enterprises: 'tf-dev-hmi-enterprises-table',
        logs: 'tf-dev-hmiportal-log-table'
    },
    aws: {
        "aws_project_region": "us-east-2",
        "aws_content_delivery_bucket": "healthymindweb-dev",
        "aws_content_delivery_bucket_region": "us-east-2",
        "aws_content_delivery_url": "http://healthymindweb-dev.s3-website.us-east-2.amazonaws.com",
        "aws_cloud_logic_custom": [
            {
                "name": "elasticsearchhmi",
                "endpoint": "https://sbpepj2da7.execute-api.us-east-2.amazonaws.com/dev",
                "region": "us-east-2"
            }
        ]
    }
}
