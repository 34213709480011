import React from 'react'
const toUpper = {
    textTransform: 'uppercase'
}

const Pageheader = (props => {
    return (
        <div className="row no-gutters">
        <h1 style={toUpper}>{ props.title }</h1>
    </div>
    )
})

export default Pageheader;