import {
  FETCH_TEAMS_BEGIN,
  FETCH_TEAMS_SUCCESS,
  FETCH_LOADMORE_TEAMS_BEGIN,
  CREATE_TEAM_SUCCESS,
  START_EDIT,
  RESET_EDIT,
  UPDATE_TEAM_SUCCESS,
  DELETE_TEAM_SUCCESS,
  UPDATE_STATUS_SUCCESS,
  UNEXPECTED_ERROR
} from './teamActions';

const initialState = {
  items: [],
  loading: false,
  error: null,
  LastEvaluatedKey: null,
  status: 'active',
  progress: false,
  editedItem: null,
  loadmore: false,
  headers: [    
      {
          type: 'checkbox'
      },
      {
          name: 'Name'
      },
      {
        name: 'Enterprise'
      },
      {
          name: 'Owner'
      },
      {
          name: 'Created Date'
      },
      {
          name: 'Action'
      }
  ]
};


export default function teamReducer(state = initialState, { type, payload }) {
  switch (type) {
   
    case FETCH_TEAMS_BEGIN:
      return {
        ...state,
        progress: true,
        error: null,
        LastEvaluatedKey: null,
        status: 'active',
        editedItem: null
      };
    case FETCH_LOADMORE_TEAMS_BEGIN:
      return {
        ...state,
        loadmore: true
      }

    case FETCH_TEAMS_SUCCESS:
      return {
        ...state,
        progress: false,
        //loadmore: false,
        ...payload
      };
    // 
    case CREATE_TEAM_SUCCESS:
      let updatedItems = [payload, ...state.items];
      return {
        ...state,
        items: updatedItems
      };
    // 
    case START_EDIT:
      return {
        ...state,
        editedItem: payload,
      };
    
    case RESET_EDIT: 
      return {
        ...state,
        editedItem: null
      }

    case UPDATE_TEAM_SUCCESS: {
      const updatedItems = [...state.items];
      const itemIndex = updatedItems.findIndex(item => payload.id === item.id);
      updatedItems[itemIndex] = payload;
      return {
        ...state,
        editedItem: null,
        items: updatedItems
      }
    }
    case DELETE_TEAM_SUCCESS: {
      const updatedItems = state.items.filter(item => !payload.ids.includes(item.id));
      return {
        ...state,
        items: updatedItems,
      }
    }
    case UPDATE_STATUS_SUCCESS: {
      const updatedItems = state.items.filter(item => !payload.ids.includes(item.id));
      return {
        ...state,
        items: updatedItems,
      }
    }

    case UNEXPECTED_ERROR: {
      return {
        ...state,
        error: payload.message
      }
    }
    
    default:
      return state;
  }
}