import React, { Component } from 'react';
import './App.scss';
import { translate } from "react-i18next";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/protectedroute';
import SignIn from './containers/signin/signin';
import ChangePassword from './containers/changepassword/changepassword';
import ForgotPassword from './containers/forgotpassword/forgotpassword';
import Content from './components/content';
import AWS from 'aws-sdk';
import Amplify from 'aws-amplify';
import Alert from 'react-s-alert';
import { ENVIRONMENTS }  from './environment';

// import aws_exports from './aws-exports';
import { library } from '@fortawesome/fontawesome-svg-core'
import store, { AmplifyBridge } from './store/auth';

import {
  faUserEdit,
  faCog,
  faUserSlash,
  faUserTimes,
  faUserCheck,
  faSync,faSort,
  faSortUp,
  faSortDown,
  faSortAlphaDown,
  faSortAlphaUp
} from '@fortawesome/free-solid-svg-icons';
Amplify.configure(ENVIRONMENTS.aws);
AWS.config.update({ region: ENVIRONMENTS.aws.aws_project_region});
Amplify.configure({
  Auth: {        
      identityPoolId: 'us-east-2:a6366c23-35c0-4e07-8463-25d31366ef51',      
      region: 'us-east-2',       
      identityPoolRegion: 'us-east-2',       
      userPoolId: 'us-east-2_YxLjMP78G',
      userPoolWebClientId: '2uh5k82hfketvbd8r2defh37jv',
      mandatorySignIn: false
  }
});
library.add(
  faUserEdit,
  faCog,
  faUserSlash,
  faUserTimes,
  faUserCheck,
  faSync,
  faSort,
  faSortUp,
  faSortDown,
  faSortAlphaDown, 
  faSortAlphaUp
)
const amplifyBridge = new AmplifyBridge(store);

class App extends Component {

  state = { 
    authProgress: true ,
    profile: null,
    signinerror: null
  }
  componentDidMount() {
    this.unsubscribeStore = store.subscribe(this.storeListener);
  }

  componentWillUnmount() {
    this.unsubscribeStore();
  }

  storeListener = () => {
   const profile = store.getState();
   
   if(profile && profile.error) {
     this.setState({
      servererror: profile.error
     })
   } else {
    this.setState(
      { profile: profile, authProgress: false }
    )
   }  
  }
  passwordChanged = () => {
    amplifyBridge.checkUser();
  }

  render() {
    const {profile, authProgress, servererror} = this.state;
    return (
     <BrowserRouter>
        <div className="App">
            <Alert stack={true} timeout={3000} />        
            <Switch>
                <Route exact path="/signIn" render={(props) => <SignIn {...props} profile={profile} servererror={servererror} authProgress={authProgress} />} />
                <Route exact path="/changePassword" render={(props) => <ChangePassword {...props} profile={profile} authProgress={authProgress} passwordChanged={this.passwordChanged} />} />
                <Route exact path="/forgotPassword" render={(props) => <ForgotPassword {...props} profile={profile} authProgress={authProgress} />} />
                <ProtectedRoute path="/" component={Content} profile={profile} authProgress={authProgress}/>              
            </Switch>        
        </div>
      </BrowserRouter>
    );
  }
}

export default translate("translations")(App);
