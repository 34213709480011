import React, {PureComponent} from 'react'
import { Trans, withI18n } from "react-i18next";
import {connect} from 'react-redux';
import moment from 'moment'
import { EnterpriseAutoComplete, TeamAutoComplete } from '../../../components/autocomplete/';
import { scrollToTop } from '../../../utils/utilityfunctions';
import {checkValidity, validateOnsubmit} from '../../../utils/form-validations';
import errorClass from '../../../scss/modules/input.module.scss';
import * as Groups  from "../../../utils/roledropdown";
import { createUsers, updateUser, resetEdit } from "../../../store/appdata/user/updateActions";
import { showLoader, hideLoader } from "../../../components/loadingComponent";

function getUpdatedFormData(item) {
    let updatedData = formInitState();
    for (let elementIdentifier in updatedData) {        
      updatedData[elementIdentifier].touched = true;
      updatedData[elementIdentifier].valid = true;
    } 

    updatedData.firstName.value = item.userInfo.firstName;
    updatedData.lastName.value = item.userInfo.lastName ? item.userInfo.lastName : '';
    updatedData.email.value = item.email;
    updatedData.userRole.value = Groups.rolesIndex.byindex[item.userRole].key;

    if(item.enterpriseId) {
        updatedData.enterprise.value = {
            id: item.enterpriseId,
            eInfo: {
                name: item.userInfo.enterprise
            }
        }
    }

    if(item.teamId) {
        updatedData.team.value = {
            id: item.teamId,
            tInfo: {
                name: item.userInfo.team
            }
        }
    }

    return updatedData;
}
  function formInitState() {
        const formData = {
            firstName: {
              value: '',
              valid: false,
              touched: false,
              message: <Trans>First name should not be empty.</Trans>,
              focused: false,
              validation: {
                isRequired: true,
                isCharacters: true,
              },
              errorMessages: {
                isRequired: <Trans>First name should not be empty.</Trans>,
                minLength: <Trans>Please enter valid first name.</Trans>
              } 
            },
            lastName: {
              value: '',
              valid: false,
              touched: false,
              message:  <Trans>Last name should not be empty.</Trans>,
              focused: false,
              validation: {
                isRequired: true,
                isCharacters: true
              },
              errorMessages: { 
                  isCharacters: <Trans>Please enter valid first name.</Trans>,       
                  isRequired: <Trans>Last name should not be empty.</Trans>
              } 
            },
            email: {
              value: '',
              valid: false,
              touched: false,
              message: <Trans>Email should not be empty.</Trans>,
              focused: false,
              validation: {
                isRequired: true,
                isEmail: true,
              },
              errorMessages: {       
                isRequired: <Trans>Email should not be empty.</Trans>,
                isEmail: <Trans>Please enter valid email.</Trans>,
              }     
            },
            userRole: {
              value: '',
              valid: false,
              touched: false,
              message: <Trans>Please select user role.</Trans>,
              focused: false,
              validation: {
                isRequired: true,
              },
              errorMessages: {       
                isRequired: <Trans>Please select user role.</Trans>,
              }     
            },
            enterprise: {
                value: null,
                valid: true
              },
            team: {
            value: null,
            valid: true
            }
          }
          return formData;
    }


class Form extends PureComponent {
    
    state = {
        data: formInitState(),
        formIsValid: false,
        formMode: 'create',
        submitBtnText: 'Submit',
        created: false,
        error: null,
        editedItem: null
    }
    componentDidMount = () => {
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if(nextProps.editedItem && (nextProps.editedItem.id !== prevState.editedItem)) {           
            const updatedData = getUpdatedFormData(nextProps.editedItem);
            scrollToTop();
            return {
                editedItem: nextProps.editedItem.id,                
                data: updatedData,
                formIsValid: true,
                formMode: 'update',
                submitBtnText: 'Update',
                error: null
            }
        }
        else if(!nextProps.editedItem && prevState.editedItem)  {
            return {
                editedItem: null,                
                data: formInitState(),
                formIsValid: false,
                formMode: 'create',
                submitBtnText: 'Submit',
                error: null
            }
        }
        return null;   
     }
    
    inputChange = (event) => {        
        const type = event.target.id;
        const value = event.target.value;       
        this.setValue(value, type);
    }

    onSelect = (event, type)  => {
        this.setValue(event, type)       
    }
    
    setValue = (value, type) => {
        const updatedData  = {...this.state.data};
        let updatedElement = {
          ...updatedData[type]
        }
        updatedElement.value = value;
       
        const valid = checkValidity(value, updatedElement.validation);
        updatedElement.valid = valid.status;
        updatedElement.message = !valid.status ? updatedElement.errorMessages[valid.key] : null;
        updatedData[type] = updatedElement;
        let formIsValid = true;
        for (let elementIdentifier in updatedData) {        
          formIsValid = updatedData[elementIdentifier].valid && formIsValid;
        } 
        this.setState({          
            error: null,
            formIsValid: formIsValid,
            data: updatedData
        });        
    }

    inputBlurred = (event) => {
        const inputId = event.target.id;
        const updatedData = {...this.state.data};
        updatedData[inputId].focused = false;
        this.setState({
            data: updatedData
        })
    }
    inputFocused = (event) => {
        const inputId = event.target.id;
        const updatedData = {...this.state.data};
        updatedData[inputId].focused = true;
        updatedData[inputId].touched = true;
        this.setState({
            data: updatedData
        })
    }
  
    onSubmit = (event) => {
        event.preventDefault();
        const formIsValid = this.state.formIsValid;
        const data = {...this.state.data}
        if(!formIsValid) {     
         const updatedData = validateOnsubmit(data);
          this.setState(
            {
              data:updatedData
            });
          return;
        }
        showLoader({target: 'manageForm'});
        const email  = data.email.value.toLowerCase();
        const time = moment().utc().format();
        const fullName =  (`${data.firstName.value} ${data.lastName.value}`).toLowerCase();
        const userRoleIndex = Groups.rolesIndex[data.userRole.value].index;
        const userInfo = {
            firstName: data.firstName.value,
            lastName: data.lastName.value,            
            updatedAt: time,
            enterprise: data.enterprise.value ? data.enterprise.value.eInfo.name: null,
            team: data.team.value ? data.team.value.tInfo.name: null   
        };
        const item = {
            userRole: userRoleIndex,
            fullName: fullName,
            email: email                         
        }

        if(data.enterprise.value) {
            item['enterpriseId'] = data.enterprise.value.id;
            userInfo['enterprise'] = data.enterprise.value.eInfo.name
        }

        if(data.team.value) {
            item['teamId'] = data.team.value.id;
            userInfo['team'] = data.team.value.tInfo.name
        }

        if(this.state.formMode === 'create') {
            item['userInfo'] = userInfo;
            item['createdAt'] = time;
            const userData = {
                item,
                role: data.userRole.value
            }
           
            this.props.createUsers(userData).then((data) => {
                if(data.status === 'success') {
                    this.successCallback();
                } else {
                    this.errorCallback(data.status);
                }
            })
            .catch(error => {
                this.errorCallback(error);
            });            
        } else { 

            item['userInfo'] = {
                ...this.props.editedItem.userInfo,
                ...userInfo
            }
            const updatedItem  = {
                ...this.props.editedItem,
                ...item
            }
            const userData = {
                item: updatedItem,
                role: data.userRole.value,
                prevrole: Groups.rolesIndex.byindex[this.props.editedItem.userRole].key
            }

            this.props.updateUser(userData).then((data) => {
                if(data.status === 'success') {
                    this.successCallback();
                } else {
                    this.errorCallback(data.status);
                }
            })
            .catch(error => {
                this.errorCallback(error);
            });            
        }
    }

    successCallback = () => {
        hideLoader();
        this.setState({
            ...this.initialState(),
            created: true,
        })
        setTimeout(() => {
            this.setState({                                                     
                created: false
            })
        }, 5000)
    }

    errorCallback = (error) => {
        hideLoader();
        this.setState({
            created: false,
            error: error
        })
    }

    resetForm = (event) => {
        event.preventDefault();
        if(this.props.editedItem)
         this.props.resetEdit()
        else {
            this.setState({
                ...this.initialState()
            })
        }   
    }

    initialState = () => {
        return {            
            data: formInitState(),
            formIsValid: false,
            submitBtnText: 'Submit',
            formMode: 'create',
            error: null                           
        }
    }

    render() {
       const groupoptions = Groups[this.props.authgroup];
       const optionskeys = Object.keys(groupoptions);
       const {data} = this.state;
      return (
        <div className="row no-gutters position-relative">           
            <form className='w-100 signup-form' id="manageForm" onSubmit={this.onSubmit} noValidate>
                <div className='formFieldsAlignments'>                   
                    <div className="form-group position-relative">
                        <label htmlFor="firstName"><Trans>firstName</Trans></label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={data.firstName.value}
                            className={!data.firstName.valid && data.firstName.touched && !data.firstName.focused ? "form-control "+errorClass.Invalid: 'form-control'}
                            onChange={this.inputChange}
                            onFocus={this.inputFocused}
                            onBlur={this.inputBlurred}
                            />
                            {(!data.firstName.valid && (data.firstName.touched && !data.firstName.focused)) && (
                                <p className={errorClass.InvalidMessage}>{data.firstName.message}</p>
                                )}
                    </div>
                    <div className="form-group position-relative">
                        <label htmlFor="lastName"><Trans>lastName</Trans></label>
                        <input 
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={data.lastName.value}
                            className={!data.lastName.valid && data.lastName.touched && !data.lastName.focused ? "form-control "+errorClass.Invalid: 'form-control'}
                            onChange={this.inputChange} 
                            onFocus={this.inputFocused}
                            onBlur={this.inputBlurred}
                            />
                        {(!data.lastName.valid && (data.lastName.touched && !data.lastName.focused)) && (
                            <p className={errorClass.InvalidMessage}>{data.lastName.message}</p>
                        )}
                    </div>
                    <div className="form-group position-relative">
                        <label htmlFor="email"><Trans>email</Trans></label>
                        <input type="email"
                            id="email" 
                            name="email" 
                            value={data.email.value} 
                            className={!data.email.valid && data.email.touched && !data.email.focused ? "form-control "+errorClass.Invalid: 'form-control'}
                            onChange={this.inputChange}
                            onFocus={this.inputFocused}
                            onBlur={this.inputBlurred}
                            readOnly={this.state.formMode ==='update' ? true : false}
                            />
                        {(!data.email.valid && (data.email.touched && !data.email.focused)) && (
                            <p className={errorClass.InvalidMessage}>{data.email.message}</p>
                        )}
                    </div>

                    <div className="form-group">
                            <label htmlFor="enterprise"><Trans>Enterprise</Trans></label>
                            <div className="position-relative">
                                <EnterpriseAutoComplete
                                    IndexName='estatus-index'
                                    IndexKey='eStatus'
                                    KeyValue='active'
                                    value={data.enterprise.value}
                                    onChange={this.onSelect}
                                    classes="react-select custom-react-select-width"
                                    prefixClass="custom-select"
                                    isClearable={true}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="enterprise"><Trans>Team</Trans></label>
                            <div className="position-relative">
                                <TeamAutoComplete
                                    IndexName='tstatus-index'
                                    IndexKey='tStatus'
                                    KeyValue='active'
                                    value={data.team.value}
                                    onChange={this.onSelect}
                                    classes="react-select custom-react-select-width"
                                    prefixClass="custom-select"
                                    isClearable={true}
                                />
                            </div>
                        </div>
                    
                    <div className="form-group position-relative">
                        <label htmlFor="userRole"><Trans>role</Trans></label>                
                        <select id="userRole"
                        value={data.userRole.value}
                            name="userRole" 
                            className={!data.userRole.valid && data.userRole.touched && !data.userRole.focused ? "form-control "+errorClass.Invalid: 'form-control'}
                            onChange={this.inputChange}
                            onFocus={this.inputFocused}
                            onBlur={this.inputBlurred}
                            disabled={this.state.formMode === 'update' && this.state.editedItem === this.props.profile.id ? true : false}
                            >
                            <option value="">Select</option>
                            {optionskeys.map((val, index) => <option key={index} value={val}>{groupoptions[val]}</option>)}
                        </select>
                        {(!data.userRole.valid && (data.userRole.touched && !data.userRole.focused)) && (
                            <p className={errorClass.InvalidMessage}>{data.userRole.message}</p>
                        )}                   
                    </div>
                    </div> 
                    <div className="justify-content-end d-flex">
                        { this.state.created && (
                            <p className="py-1 alert alert-success mr-3 mb-0"><Trans>User successfully created.</Trans></p>
                        )
                        }
                        { this.state.error && (
                            <p className="py-1 alert alert-danger mr-3 mb-0">{this.state.error}</p>
                        )
                        }                        
                        <div><button type="submit" className="btn btn-primary mr-3"><Trans>{this.state.submitBtnText}</Trans></button></div>
                        
                        <div><button type="button" className="btn btn-secondary" onClick={this.resetForm}><Trans>Cancel</Trans></button></div>
                    </div>
                </form>
            </div>

        );
    }
  }

  const mapStateToProps = (state) => {
    const {users} = state;
    return ({
       editedItem: users.editedItem
       })
    }
  const mapDispatchToProps = {
    createUsers,
    updateUser,
    resetEdit
  };

  export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(Form));