import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import logininfo from '../../assets/img/login-img.png';
import logo from '../../assets/img/logo-hmi-white.png';
import Logo from '../../components/logo';
import { ClipLoader } from 'react-spinners';
import { NavLink } from "react-router-dom";
import './signin.scss';
import { Trans } from "react-i18next";
import { withI18n } from "react-i18next";
import styles from '../../scss/modules/loader.module.scss';

class SignIn extends Component {

    state = {
        username: null,
        password: null,
        loading: false,
        valid: true,
        wrongvalue: <Trans>Incorrect username or password. Please try again</Trans>,
        emptyvalue: <Trans>Please provide username and password.</Trans>,
        errormsg: null,
    }

    inputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        if (!this.state.valid) {
            this.setState({
                valid: true
            });
        }        
    }

    SubmitLogin = async(event) =>{
        event.preventDefault();        
        let { username, password } = this.state;
        if ((username && password) && !this.state.loading) {
            username = username.toLowerCase();
            this.setState({ loading: true });
            Auth.signIn(username, password)
                .then((user) => {
                    let redirect = '/changePassword';
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        redirect = '/changePassword';
                        this.props.history.push(redirect, { username: username, password: password });
                     }                  
                })
                .catch(err => {
                    this.setState({ loading: false, valid: false, errormsg: this.state.wrongvalue });
                });
        } else {
            this.setState({ valid: false, errormsg: this.state.emptyvalue });
        }
    }

    render() {
        let { loading } = this.state;
        const { t, profile, authProgress, servererror } = this.props;
        let loaderClass = null;
        if (loading) {
            loaderClass = 'loading';
        }
        return (   
            !authProgress ? (     
            !profile || servererror ? (
                <div className="Login_container">
                    <ClipLoader
                        className="clipLOader"
                        sizeUnit={"px"}
                        size={50}
                        color={'#fff'}
                        loading={loading}
                    />
                    <div className={"Login " + loaderClass}>
                        <div className="d-none d-sm-block">
                            <img className="Login_info" src={logininfo} alt="" />
                            <div className="Login_logo">
                                <img className="Logo" src={logo} alt="" />
                            </div>
                        </div>
                        <div className="Login_form">
                            <Logo/>
                            <h1><Trans>signIn</Trans></h1>
                            {!this.state.valid && <p className="Login_error pl-2">{this.state.errormsg} {this.state.signinerror}</p>}
                            {servererror && <p className="Login_error pl-2">{servererror}</p>}

                            <form onSubmit={(event) => this.SubmitLogin(event)}>
                                <div className="form-group">
                                    <input className="form-control" type="text" name="username" placeholder={t('userName')} onChange={this.inputChange} />
                                </div>
                                <div className="form-group mb-1">
                                    <input className="form-control" type="password" name="password" placeholder={t('password')} onChange={this.inputChange} />
                                </div>
                                <p className="text-right">
                                    <NavLink exact className="f-link" to="/forgotPassword"><Trans>forgotPasswordwithQuestionMark</Trans></NavLink >
                                </p>
                                <div>
                                    <button type="submit" className="btn"><Trans>login</Trans></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : (
                <Redirect to={{
                    pathname: '/',
                    state: { from: this.props.location }
                }}
                />
            )          
            ): 
            <div className={styles.appLoading}>
                <div className={styles.hmiLoadingSpinner}></div>
            </div>       
            )
    }
}

export default withI18n()(SignIn);