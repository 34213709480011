import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans  } from "react-i18next";

const userAction = (props => {
    return (
       <td onClick={(event) => props.onAction(event, props.userInfo.id, props.userAction)}>
       <div>             
           <React.Fragment>
               <span className="pointer mr-3"
                   data-title="editUser"                                                                  
                   style={{color: '#2f63dc'}}>
               <FontAwesomeIcon
                   icon={['fas', 'user-edit']}                  
                   fixedWidth={false}
                   size="1x"
               /> <Trans>edit</Trans> 
               </span> 
            </React.Fragment>

        {!props.userInfo['currentuser'] && (
            <React.Fragment>
                | <span className="pointer ml-3"                                                                   
                    data-title="deleteUser"
                    style={{color: '#fc7169'}}>
                <FontAwesomeIcon
                        icon={['fas', 'user-times']}                  
                        fixedWidth={false}
                        size="1x"
                    /> <Trans>Delete</Trans>
                </span>
            </React.Fragment>
        )}
       </div>
   </td>
    )
})

export default userAction;