import { API } from 'aws-amplify';
import { ENVIRONMENTS }  from '../../../environment';
import signedin from '../signedinUser';
export const FETCH_USERS_BEGIN   = 'FETCH_PRODUCTS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const FILTER_USERS_BEGIN = 'FILTER_USERS_BEGIN';
export const FETCH_LOADMORE_BEGIN = 'FETCH_LOADMORE_BEGIN';
const usertable = ENVIRONMENTS.dynamoDB['users'];
const size = 20;

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});
export const filterUsersBegin = () => ({
  type: FILTER_USERS_BEGIN
});
export const fetchLoadmoreBegin = () => ({
  type: FETCH_LOADMORE_BEGIN
});

export const fetchusersSuccess = users => ({
  type: FETCH_USERS_SUCCESS,
  payload: users
});

export const fetchUsersFailure = error => ({
  type: FETCH_USERS_FAILURE,
  payload: { error }
});

export function fetchUsers({loadmore = false} = {}) {

  return (dispatch, getState )=> {

      if(!loadmore) {
          dispatch(fetchUsersBegin());
        }
        else {
          dispatch(fetchLoadmoreBegin());
        }
        const prevState = getState().users;
        const reqParams = {
          TableName : usertable,
          Limit: size
        }
        if(prevState.LastEvaluatedKey && loadmore)
          reqParams.ExclusiveStartKey = prevState.LastEvaluatedKey;

        const params  = {
            ...reqParams,
            FilterExpression: 'userRole >= :role',
            ExpressionAttributeValues: { ":role": signedin.profile.userRole }
        }
        signedin.profile.docClient.scan(params,
          (err, data) => {
            if(err) {
              dispatch(fetchUsersFailure(err.message));
            }
            if(data) {
              let items = data.Items;
              if(loadmore) {
                const prevUsers = prevState.items;
                items =  [...prevUsers, ...items];
              }
              const retData = {
                items,
                LastEvaluatedKey: data.LastEvaluatedKey,
                
              }
              dispatch(fetchusersSuccess(retData));
            }
        });  
    };
  }

  export function filterUsers({type, value, loadmore = false}) {
  
    return (dispatch, getState )=> {
      const prevState = getState().users;
      const { query } = prevState;
      //const filterQuery = {...query};
      let from = 0;
     
      if(type) {
        dispatch(filterUsersBegin()); 
         query[type] = value;
        // filterQuery[type]= JSON.stringify(value);        
      } 

      else if(loadmore) {
        dispatch(fetchLoadmoreBegin());
        query.pagination.pagenumber = loadmore ? query.pagination.pagenumber + 1 : 1;
        from = query.pagination.size * (query.pagination.pagenumber - 1);
      }  
      let filterQuery = {};
      for (let key in query) {
        if (query.hasOwnProperty(key)) {
            const value = (query[key] && typeof  query[key]) === 'object' ? JSON.stringify(query[key]) : query[key];
            filterQuery[key] = value;
        }
      }
      const params= {
          ...filterQuery,
          userRole: signedin.profile.userRole,
          from: from,
          loadmore
      }  
      let apiRequest = {
        queryStringParameters: params
    }
      
      API.get('elasticsearchhmi', '/search/filter', apiRequest)
        .then(async res => {  
          
          const hits = res.data.hits;  
          const showmorebtn = (query.pagination.pagenumber * size) < hits.total;
          let items = hits.hits.map( source => source._source);
          if(loadmore) {
            items =  [...prevState.items, ...items];
          }
          const retData = {
            items,
            query,
            showmorebtn
          }

          dispatch(fetchusersSuccess(retData));       
        })
        .catch(error => {
          dispatch(fetchUsersFailure(error));         
        });
      }
    }