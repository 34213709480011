import React, { Component } from 'react'
import Pageheader from '../../components/pageheader';
import Navtab from '../../components/navtab';
import Table from './components/table';
import { ClipLoader } from 'react-spinners';
import { confirmAlert } from 'react-confirm-alert';
import { Trans, withI18n } from "react-i18next";
import  ProcessCSV  from "./components/processcsv";
import { ENVIRONMENTS }  from '../../environment';
import ExportUsers from "./components/exportusers";
import {connect} from 'react-redux';
import { fetchUsers } from "../../store/appdata/user/fetchActions";
import Form from "./components/form";

//import axios from 'axios';

class ManageUsers extends Component {
    constructor() {
        super();
        this.state = {          
            showCSVImport : false,
            dataToDownload : [],
            exportUserSelected : 'all'
        }
        this.usertable = ENVIRONMENTS.dynamoDB['users'];
        this.profile = {};
    }
    
 
    componentWillMount = () => {
        this.profile = this.props.profile; 
    }
    componentDidMount = () => { 
        this.props.fetchUsers();      
    }

    errorAlert = (message, title=this.props.t("Unable to process your request")) => {  
        confirmAlert({
            customUI: ({ onClose }) => {
              return (                 
                 <div className='custom-ui'>                                                   
                  <h4>{title}</h4>                
                  <p style={{color: "red"}}>{this.props.t('Error:')} {message}</p>
                  <button onClick={onClose} ref={(c)=> c && c.focus()}>{this.props.t('Ok')}</button>                 
                </div>               
              )
            }
        })
    }

    setImportCSVPart = ()=>{
        this.setState({ showCSVImport : !this.state.showCSVImport});
    }

    updateImportedData = () => {   
        this.props.fetchUsers();
    }


    render() {        
        const {group} = this.profile;        
        return (
            <div className="content-wrapper">                    
                {this.props.loadingPage && (
                    <div className="loader-overlay">
                    <ClipLoader
                        className="clipLOader"      
                        sizeUnit={"px"}
                        size={50}
                        color={'#fff'}
                        loading={this.props.loadingPage}
                        />
                    </div>
                )}
                <div>
                <Pageheader title={<Trans>manageUsers</Trans>} />
                <div className="row no-gutters">
                {!this.props.editedItem ? (
                    <Navtab tabs={[{id: 1 , name:  <Trans>addUser</Trans>}]} />               
                    ): (
                    <Navtab tabs={[{id: 1 , name:  <Trans>updateUser</Trans>}]} />               
                    )}
                 </div>
                 <Form authgroup={group} profile={this.profile} /> 
                </div>
                { this.state.showCSVImport &&  
                    <div style={{paddingTop: '23px'}}>
                        <div className="row no-gutters">
                            <Navtab tabs={[{id: 1 , name:  <Trans>Import CSV</Trans>}]} />               
                        </div>
                        <ProcessCSV />
                    </div> 
                } 
                <div className="justify-content-end d-flex">
                    <button onClick={this.setImportCSVPart}
                            className="btn btn-primary mr-3"
                            style={{marginTop: '20px', marginBottom: '-36px'}}>
                                {this.state.showCSVImport ?  <Trans>Cancel</Trans> : <Trans>Import Users</Trans>}
                            </button>
                </div>
                <Table 
                     tabs={[{id: 1 , name: 'Users'}]}
                     profile = {this.profile}
                     /> 

            <div style={{ float : 'right', marginBottom: '20px'}}>         
                <ExportUsers
                    usertable={this.usertable}                   
                    errorAlert={this.errorAlert}
                    />
                </div> 
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.users.items,
    loadingPage: state.users.loadingPage,
    editedItem: state.users.editedItem
})
const mapDispatchToProps = {
    fetchUsers
  };

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(ManageUsers));
