import { ENVIRONMENTS }  from '../../../environment';
import signedin from '../signedinUser';
import { writeUserActivityLog } from '../../../utils/utilityfunctions';

export const FETCH_ENTERPRISES_BEGIN = 'FETCH_ENTERPRISES_BEGIN';
export const FETCH_ENTERPRISES_SUCCESS = 'FETCH_ENTERPRISES_SUCCESS';
export const FETCH_LOADMORE_ENTERPRISES_BEGIN = 'FETCH_LOADMORE_ENTERPRISES_BEGIN';
export const CREATE_ENTERPRISE_SUCCESS = 'CREATE_ENTERPRISE_SUCCESS';
export const START_EDIT = 'START_EDIT';
export const RESET_EDIT = "RESET_EDIT"
export const UPDATE_ENTERPRISE_SUCCESS = 'UPDATE_ENTERPRISE_SUCCESS';
export const DELETE_ENTERPRISE_SUCCESS = 'DELETE_ENTERPRISE_SUCCESS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';

const enterprisetable = ENVIRONMENTS.dynamoDB['enterprises'];
const size = 20;

export const fetchEnterpisesBegin = () => ({
  type: FETCH_ENTERPRISES_BEGIN
});

export const fetchLoadmoreEnterprisesBegin = () => ({
  type: FETCH_LOADMORE_ENTERPRISES_BEGIN
});

export const fetchEnterpriseSuccess = enterprises => ({
  type: FETCH_ENTERPRISES_SUCCESS,
  payload: enterprises
});

export const startEdit = item => ({
  type: START_EDIT,
  payload: item
})
export const resetEdit = () => ({
  type: RESET_EDIT
})
export const updateEnterpriseSuccess = item => ({
  type: UPDATE_ENTERPRISE_SUCCESS,
  payload: item
})

export const createEnterpriseSuccess = enterprise => ({
  type: CREATE_ENTERPRISE_SUCCESS,
  payload: enterprise
});

export const deleteEnterpriseSuccess = ids => ({
  type: DELETE_ENTERPRISE_SUCCESS,
  payload: ids
});

export const updateStatusSucess = ids => ({
  type: UPDATE_STATUS_SUCCESS,
  payload: ids
})

export const unexpectedError = () => ({
  type: UNEXPECTED_ERROR
});


export function fetchEnterprises({ loadmore = false, status } = {}) {
  return (dispatch, getState) => {
    if (!loadmore) 
      dispatch(fetchEnterpisesBegin());
    else 
      dispatch(fetchLoadmoreEnterprisesBegin());
      
      const prevState = getState().enterprises;

      const fetchParams = {
        TableName: enterprisetable,
        Limit: size
      }
    if (loadmore && prevState.LastEvaluatedKey)
      fetchParams.ExclusiveStartKey = prevState.LastEvaluatedKey;
    
      if(!status)
        status = prevState.status;

      const params = {
      ...fetchParams,
      IndexName: 'estatus-index',
      KeyConditionExpression: 'eStatus = :s',
      ExpressionAttributeValues: { ':s': status }
    }
    signedin.profile.docClient.query(params,
      (err, data) => {
        if (err) {
          dispatch(unexpectedError(err.message));
        }
        if (data) {
          let items = data.Items;
          if (loadmore) {
            const oldItems = prevState.items;
            items = [...oldItems, ...items];
          }
          const retData = {
            items,
            LastEvaluatedKey: data.LastEvaluatedKey,
            status: status
          }
          dispatch(fetchEnterpriseSuccess(retData));
        }
      });
  };
}

export function createEnterprise(item) {

  return (dispatch, getState) => {      
    
    const result = new Promise((resolve, reject) => {

      signedin.profile.docClient.put({
        TableName: enterprisetable,
        Item: item
    }, (err, data) => {
      if(err) {
        resolve({status: err.message});
      } else {
        dispatch(createEnterpriseSuccess(item));
        writeUserActivityLog('Enterprise created : ' + item.eName , 'enterprise');
        resolve({status: 'success'});
      }
    })

    })
    
      result.then((data) => {          
      })
      return result;

}

}

export function updateEnterprise(item){
  return (dispatch, getState) => {
    
    const result = new Promise( (resolve, reject ) => {
      let expressionAttrName = {
        "#en": "eName",
        "#einfo":"eInfo",
        "#name":'name'
    }

    let expressionAttrValues = {
        ":en": item.eName,
        ":name": item.eInfo.name
    }
    let UpdateExpression = 'set #en = :en, #einfo.#name=:name';

    if(item.eInfo.owner) {
        expressionAttrName["#owner"] = 'owner';        
        UpdateExpression += ',#einfo.#owner=:owner';
        expressionAttrValues[":owner"] = item.eInfo.owner;
    }
 
    signedin.profile.docClient.update({
        TableName: enterprisetable,
        Key: {
            id: item.id
        },
        UpdateExpression: UpdateExpression,
        ExpressionAttributeNames: expressionAttrName,
        ExpressionAttributeValues: expressionAttrValues,
      }, (err, data) => {
        if(err) {
          resolve({status: 'error'});
        }else {
          dispatch(updateEnterpriseSuccess(item));          
          resolve({status: 'success'});
          writeUserActivityLog('Enterprise updated : ' + item.eName , 'enterprise');

        }
      })

    
    })     
    return result;
  }
}

export function deleteEnterprises(ids){

  return (dispatch, getState) => {

    const deleteObject = ids.reduce( (accumulator, id) => {  
     
      accumulator.push(
          {
            DeleteRequest: {
              Key: {
              id: id                                                      
              }
            }
          } 
        ) 
        return accumulator;          
    }, []);

    return new Promise((resolve, reject) => {
          
        var params = {
          RequestItems: {
          [enterprisetable]: deleteObject
          }
        };
        signedin.profile.docClient.batchWrite(params, (err, data) => {
          if (err) {
            resolve({error:err.message})
          } 
          else    {
            const enterprises = getState().enterprises.items;
            const enterprisenames = enterprises.filter(enterprise => ids.includes(enterprise.id))
                              .map(enterprise => enterprise.eName)
            writeUserActivityLog(`Enterprises Deleted: ${JSON.stringify(enterprisenames)}`, 'enterprise');
            dispatch(deleteEnterpriseSuccess({ids}));
          }  
          resolve()         
        });         
    })

  }
}

export function updateStatus(ids, status) {

  return (dispatch, getState) => {    
    let result = ids.reduce( (accumulatorPromise, id) => {  
      return accumulatorPromise.then(() => {        
        return updateEnterpriseAsync(id, status);
      })         
    }, Promise.resolve());
    result.then(() => { 
      const enterprises = getState().enterprises.items;
      const enterprisenames = enterprises.filter(enterprise => ids.includes(enterprise.id))
                        .map(enterprise => enterprise.eName)
      writeUserActivityLog(`Enterprises Updated: ${JSON.stringify(enterprisenames)}`, 'enterprise');
          dispatch(updateStatusSucess({ids}));               
    })
    return result;

    function updateEnterpriseAsync(id, status) {
      return new Promise((resolve, reject) => {      
          signedin.profile.docClient.update({
            TableName: enterprisetable,  
            Key: {
                id: id
            },
            UpdateExpression: 'set #s = :s',
            ExpressionAttributeNames: {
                "#s": "eStatus"
            },
            ExpressionAttributeValues: {
                ":s": status
            }              
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve();
            }
        });

      })
    }
  }

}