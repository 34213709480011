import React from 'react';
import Header from './header';
import Navigation from './navigation';
import ManageUsers from '../containers/manageusers/manageusers';
import ManageEnterprises from '../containers/manageenterprises/manageenterprises';
import ManageTeams from '../containers/manageteams/manageteams';
import Logs from '../containers/logs/logs';
import { Route, Switch, Redirect } from 'react-router-dom';

export class Content extends React.Component {
  
  render() {    
    const profile = this.props.profile
    return (
      // <AuthConsumer>
      // <React.Fragment>
      //   {({ attributes, logout, user }) =>
          <React.Fragment>
            {/* <Header logout={(event) => logout(event)} useremail={attributes.userInfo.email} /> */}
           
            <Header useremail={profile.email} />
            <div className="container-fluid page-content-wrapper">
              <Navigation group={profile.group}/>
              <div className="main-panel">
                <Switch>
                  {/* <Route exact path="/" render={() => <ManageUsers attributes={attributes} {...user} />} /> */}
                  <Route exact path="/" render={() => <ManageUsers profile={profile} />} />
                  <Redirect from='/manageusers' to='/'/>
                  {/* <Route exact path="/manageenterprises" render={() => <ManageEnterprises {...user} />} /> */}
                  <Route exact path="/manageenterprises" render={() => <ManageEnterprises profile={profile} />} />
                  {/* <Route exact path="/manageteams" render={() => <ManageTeams email={user.email} />}   /> */}
                  <Route exact path="/manageteams" render={() => <ManageTeams profile={profile} />}   />
                  {/* <Route exact path="/logs" render={() => <Logs email={user.email} />}   /> */}
                  <Route exact path="/logs" render={() => <Logs profile={profile} />}   />
                </Switch>
              </div>
            </div>
          </React.Fragment>
        // }
        // </React.Fragment>
      // </AuthConsumer>
    );
  }
}

export default Content;