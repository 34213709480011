import React, {Component} from 'react';
import { Redirect, Route } from 'react-router-dom';
import styles from '../scss/modules/loader.module.scss';
// import store from '../store/auth';


class ProtectedRoute extends Component {
  componentDidMount() {
    //this.unsubscribeStore = store.subscribe(this.storeListener);
  }

  componentWillUnmount() {
   // this.unsubscribeStore();
  }

  // storeListener = () => {
  //  const profile = store.getState();
   
  //  this.setState(
  //    { profile: profile, authProgress: false }
  //  )
  // }

  render() {
    const { component: Component, profile, authProgress, ...rest } = this.props;
   
    return (      
      !authProgress  ? (
          <Route {...rest} render={props => (
            profile ? (
            <Component {...props} profile={profile} />
            ) : (
            <Redirect to={{
              pathname: 'signIn',
              state: { from: this.props.location }
            }}
            />
            )
          )} />           
        ): 
          <div className={styles.appLoading}>
            <div className={styles.hmiLoadingSpinner}></div>
          </div>       
     )
  }
}

export default ProtectedRoute;