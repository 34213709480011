import React from 'react';
import AsyncPaginate from "react-select-async-paginate";
import { isEmpty } from 'lodash';
import { ENVIRONMENTS }  from '../../environment';
import signedin from '../../store/appdata/signedinUser';

export class TeamAutoComplete extends React.Component {
    constructor() {
        super();
        this.state = {
            team: null
        };
        this.requestTimer = null;
        this.lastEvaluatedKey = null;
        this.teamtable = ENVIRONMENTS.dynamoDB['teams'];
    }  
    
    static defaultProps = {
        IndexName: 'typeahead-index',
        IndexKey: 'tabletype',
        KeyValue: 'team'
    }

    getTeams = (val) => {
        const profile = signedin.profile;
        let promise = new Promise(resolve => {         
            let params =  {
                TableName: this.teamtable,
                IndexName: this.props.IndexName,
                ExpressionAttributeValues: { ':s': this.props.KeyValue},
                KeyConditionExpression: `${this.props.IndexKey} = :s`,
                Limit: 20   
            }
            if(this.lastEvaluatedKey)
                params['ExclusiveStartKey'] = this.lastEvaluatedKey.id;
            if(val) {
                params['KeyConditionExpression'] = `${params['KeyConditionExpression']} AND begins_with(tName, :string)`;
                params.ExpressionAttributeValues[':string'] = val.toLowerCase();
            }

            profile.docClient.query(params, (err, data) => {
                if (err) {
                    console.log(err);          
                } else {    
                    resolve(data);                    
                }
            })
        });
        return promise;
    }

    typeTrigger = async(val, prevOptions) => {
        const teams = await this.getTeams(val);
       if(isEmpty(teams)) {
            return {
                options: [],
                hasMore: false
              };
           }
       
       this.lastEvaluatedKey = teams.LastEvaluatedKey ? teams.LastEvaluatedKey : null;
        return {
            options: teams.Items,
            hasMore: !!this.lastEvaluatedKey
          };  
    }

    render() {
      return (
            <div className="position-relative">              
                <AsyncPaginate
                    value={this.props.value}
                    loadOptions={this.typeTrigger}
                    placeholder='Team'
                    onChange={(event) => {this.props.onChange(event, 'team')}}
                    getOptionLabel={(option) => option.tInfo.name}
                    getOptionValue={(option) => option.id}
                    className={this.props.classes}
                    classNamePrefix={this.props.prefixClass}                 
                    isClearable={this.props.isClearable}
                />
            </div>
        );
    }
}
