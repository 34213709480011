import React, {PureComponent} from 'react'
import { Trans, withI18n } from "react-i18next";
import {connect} from 'react-redux';
import moment from 'moment'
 import { scrollToTop } from '../../../utils/utilityfunctions';
import {checkValidity, validateOnsubmit} from '../../../utils/form-validations';
import errorClass from '../../../scss/modules/input.module.scss';
import { showLoader, hideLoader } from "../../../components/loadingComponent";
import { EnterpriseAutoComplete, UserAutoComplete } from '../../../components/autocomplete/';
import {createTeam, updateTeam, resetEdit} from "../../../store/appdata/team/teamActions";
const uuidv1 = require('uuid/v1');

function getUpdatedFormData(item) {
    let updatedData = formInitState();
    for (let elementIdentifier in updatedData) {        
      updatedData[elementIdentifier].touched = true;
      updatedData[elementIdentifier].valid = true;
    } 

    updatedData.name.value = item.tInfo.name;
    if(item.tInfo.owner) {
        updatedData.owner.value = item.tInfo.owner;
    }
    if(item.tInfo.enterprise) {
        updatedData.enterprise.value = item.tInfo.enterprise;
    }

    return updatedData;
}
  function formInitState() {
    const formData = {
        name: {
          value: '',
          valid: false,
          touched: false,
          message: <Trans>Team name should not be empty.</Trans>,
          focused: false,
          validation: {
            isRequired: true,
            isCharacters: true,
            minLength: 2
          },
          errorMessages: {
            isCharacters: <Trans>Please enter valid team name.</Trans>,
            minLength: <Trans>Please enter valid team name.</Trans>,
            isRequired: <Trans>Team name should not be empty.</Trans>
          } 
        },
        enterprise: {
            value: null,
            valid: true
        },
        owner : {
            value: null,
            valid: true
        }
      }
      return formData;
    }


class Form extends PureComponent {
    
    state = {
        data: formInitState(),
        formIsValid: false,
        formMode: 'create',
        submitBtnText: 'Submit',
        created: false,
        error: null,
        editedItem: null,
        success:  <Trans>Team successfully created</Trans>
    }
    componentDidMount = () => {
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        if(nextProps.editedItem && (nextProps.editedItem.id !== prevState.editedItem)) {           
            const updatedData = getUpdatedFormData(nextProps.editedItem);
            scrollToTop();
            return {
                editedItem: nextProps.editedItem.id,                
                data: updatedData,
                formIsValid: true,
                formMode: 'update',
                submitBtnText: 'Update',
                error: null
            }
        }
        else if(!nextProps.editedItem && prevState.editedItem)  {
            return {
                editedItem: null,                
                data: formInitState(),
                formIsValid: false,
                formMode: 'create',
                submitBtnText: 'Submit',
                error: null
            }
        }
        return null;   
     }
    
    inputChange = (event) => {        
        const type = event.target.id;
        const value = event.target.value;       
        this.setValue(value, type);
    }

    onSelect = (event, type)  => {
        this.setValue(event, type)       
    }
    
    setValue = (value, type) => {
        const updatedData  = {...this.state.data};
        let updatedElement = {
          ...updatedData[type]
        }
        updatedElement.value = value;
       
        const valid = checkValidity(value, updatedElement.validation);
        updatedElement.valid = valid.status;
        updatedElement.message = !valid.status ? updatedElement.errorMessages[valid.key] : null;
        updatedData[type] = updatedElement;
        let formIsValid = true;
        for (let elementIdentifier in updatedData) {        
          formIsValid = updatedData[elementIdentifier].valid && formIsValid;
        } 
        this.setState({          
            error: null,
            formIsValid: formIsValid,
            data: updatedData
        });        
    }

    inputBlurred = (event) => {
        const inputId = event.target.id;
        const updatedData = {...this.state.data};
        updatedData[inputId].focused = false;
        this.setState({
            data: updatedData
        })
    }
    inputFocused = (event) => {
        const inputId = event.target.id;
        const updatedData = {...this.state.data};
        updatedData[inputId].focused = true;
        updatedData[inputId].touched = true;
        this.setState({
            data: updatedData
        })
    }
  
    onSubmit = (event) => {
        event.preventDefault();
        const formIsValid = this.state.formIsValid;
        const data = {...this.state.data}
        if(!formIsValid) {     
         const updatedData = validateOnsubmit(data);
          this.setState(
            {
              data:updatedData
            });
          return;
        }

        let id = uuidv1();        
        let info = {           
            name: this.state.data.name.value
        }

        let item = { 
            id: id,
            tName: this.state.data.name.value.toLowerCase(),
            tStatus: 'active',
            tInfo: info,
            tabletype:'team'
        }

        if(this.state.data.owner.value) {
            info['owner']=  this.state.data.owner.value
        }

        if(this.state.data.enterprise.value) {
            info['enterprise']=  this.state.data.enterprise.value
            item['enterprise'] = this.state.data.enterprise.value.id;
        }

     

        showLoader({target: 'create-form'});       

        if(this.state.formMode === 'create') {
          
            item.tInfo['createdAt'] = moment().utc().format();
        
            this.props.createTeam(item).then((data) => {
                if(data.status === 'success') {
                    this.successCallback();
                } else {
                    this.errorCallback(data.status);
                }
            })
            .catch(error => {
                this.errorCallback(error);
            });            
        } else { 
            item.id = this.props.editedItem.id;          
            this.props.updateTeam(item).then((data) => {             
                if(data.status === 'success') {
                    this.successCallback();
                } else {
                    this.errorCallback(data.status);
                }
            })
            .catch(error => {
                this.errorCallback(error);
            });            
        }
    }

    successCallback = () => {
        hideLoader();
        this.setState({
            ...this.initialState(),
            created: true,
        })
        setTimeout(() => {
            this.setState({                                                     
                created: false
            })
        }, 5000)
    }

    errorCallback = (error) => {
        this.setState({
            created: false,
            error: error
        })
    }

    resetForm = (event) => {
        event.preventDefault();
        if(this.props.editedItem)
         this.props.resetEdit()
        else {
            this.setState({
                ...this.initialState()
            })
        }   
    }

    initialState = () => {
        return {            
            data: formInitState(),
            formIsValid: false,
            submitBtnText: 'Submit',
            formMode: 'create',
            error: null                           
        }
    }

    render() {
       const {data} = this.state;
      return (
        <div className="row no-gutters position-relative">  

            <form className='w-100 signup-form' id="create-form" onSubmit={this.onSubmit} noValidate>
            <div className='formFieldsAlignments'>
                <div className="form-group position-relative">
                    <label htmlFor="name"><Trans>TeamName</Trans></label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={data.name.value}
                        className={!data.name.valid && data.name.touched && !data.name.focused ? "form-control "+errorClass.Invalid: 'form-control'}
                        onChange={this.inputChange}
                        onFocus={this.inputFocused}
                        onBlur={this.inputBlurred}
                    />
                    {(!data.name.valid && (data.name.touched && !data.name.focused)) && (
                        <p className={errorClass.InvalidMessage}>{data.name.message}</p>
                    )}
                </div>

                <div className="form-group">
                    <label htmlFor="enterprise"><Trans>enterpriseName</Trans></label>
                    <div className="position-relative">
                        <EnterpriseAutoComplete
                            IndexName='estatus-index'
                            IndexKey='eStatus'
                            KeyValue='active'
                            value={data.enterprise.value}
                            onChange={this.onSelect}
                            classes="react-select custom-react-select-width"
                            prefixClass="custom-select"
                            isClearable={true}   
                        />                     
                    </div>  
                </div>

                <div className="form-group">
                    <label htmlFor="owner"><Trans>teamOwnerName</Trans></label>
                    <div className="position-relative">
                        <UserAutoComplete 
                            value={data.owner.value}
                            onChange={this.onSelect}
                            classes="react-select custom-react-select-width"
                            prefixClass="custom-select"
                            isClearable={true}                    
                        />
                    </div>                    
                </div>
            </div>
            <div className="justify-content-end d-flex">
                {this.state.created && (
                    <p className="py-1 alert alert-success mr-3 mb-0">{this.state.success}</p>
                )
                }
                {this.state.error && (
                    <p className="py-1 alert alert-danger mr-3 mb-0">{this.state.error}</p>
                )
                }

                <div><button type="submit" className="btn btn-primary mr-3"><Trans>{this.state.submitBtnText}</Trans></button></div>

                <div><button type="button" className="btn btn-secondary" onClick={this.resetForm}><Trans>Cancel</Trans></button></div>
            </div>
            </form>
        </div>

        );
    }
  }

  const mapStateToProps = (state) => {
    const {teams} = state;
    return ({
       editedItem: teams.editedItem
       })
    }
  const mapDispatchToProps = {
    createTeam,
    updateTeam,
    resetEdit
  };

  export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(Form));


