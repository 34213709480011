import store from '../auth/index';

class signedIn {
  constructor() {
    this.profile = {};
    store.subscribe(this.storeListener);
  }
  storeListener = () => {
    this.profile = store.getState();
  }
}
const signed =  new signedIn();
export default signed;
