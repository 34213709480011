import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SVGSearchIcon from "../../../components/svgIcons/search";
import Select from 'react-select';
import { Trans } from "react-i18next";
import { EnterpriseAutoComplete, TeamAutoComplete } from '../../../components/autocomplete/';

const options = [
    { value: '', label: 'All users' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'active', label: 'Active' },
    { value: 'pending', label: 'Pending' },
    { value: 'new', label: 'New' }
];

const tableNav = (props => {
    const statusoptions = [];
    switch (props.query.status && props.query.status.value) {
        case 'active':
            statusoptions.push({ value: 'inactive', label: 'Inactivate' })
            break;
        case 'inactive':
            statusoptions.push({ value: 'active', label: 'Activate' })
            break;
        case 'new':
            statusoptions.push({ value: 'pending', label: 'Pending' })
            break;
        default: // Do nothing
    }
    const selected = [...new Set([...props.checkedItems.values()])];
    return (
        <React.Fragment>
            <div className="w-100">
                <ul className="nav nav-pills nav-fill">
                    <li className="nav-item backgroungNone mt-3 mb-3">
                        <div className="displayFlexCustom formFieldsAlignments">                        
                            <label style={{ paddingRight: '10px' }}>Filter: </label>
                            <Select
                                value={props.query.status}
                                onChange={(event) => {props.filterUser(event, 'status')}}
                                placeholder="Status"
                                options={options}
                                className="react-select"
                                isSearchable={false}
                                classNamePrefix="react-select"
                            />      
                            <div className="position-relative ml-3">                                                   
                                <EnterpriseAutoComplete
                                    value={props.query.enterprise}
                                    onChange={props.filterUser}
                                    classes="async-select"
                                    prefixClass="react-select"
                                    isClearable={true}
                                />
                            </div>
                            <div className="position-relative ml-3">                                                   
                                <TeamAutoComplete
                                    value={props.query.team}
                                    onChange={props.filterUser}
                                    classes="async-select"
                                    prefixClass="react-select"
                                    isClearable={true}
                                /> 
                            </div>                                                                  
                            {(selected.length > 1 || selected[0] === true) && (
                                <React.Fragment>
                                    {statusoptions.length > 0 && (
                                        <div className="displayFlexCustom mt-mb-6">
                                            <label className="mx-2">Change Status: </label>
                                            <Select
                                                onChange={(event) => { props.updateStatus(props.query.status.value, event.value) }}
                                                placeholder="Select"
                                                options={statusoptions}
                                                className="react-select yellowctrl"
                                                isSearchable={false}
                                                classNamePrefix="react-select"
                                            />
                                        </div>
                                    )}
                                    <div className="ml-3 user-filter mt-mb-6">
                                        <button onClick={props.deleteUsers} className="btn btn-delete"><Trans>deleteSelected</Trans></button>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </li>
                    <li className="nav-item backgroungNone  mt-3 mb-3">
                        <div className="searcharea float-right searchCustom">
                            <div className="searchCustomWidth">
                                <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search"
                                    value={props.searchStr}
                                    onKeyDown={props.searchKeyPress}
                                    onChange={props.searchHandleChange}
                                />
                                <SVGSearchIcon />
                            </div>
                            <div className="reset-table-icon" onClick={props.resetFilter}>
                                <FontAwesomeIcon
                                    icon={['fas', 'sync']}
                                    fixedWidth={false}
                                    size="1x"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </React.Fragment>           
    )
})

export default tableNav;
