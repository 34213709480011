import { combineReducers } from 'redux';
import users from "./user/userReducers";
import enterprises from "./enterprise/enterpriseReducers";
import teams from "./team/teamReducers";

const Hmireducer = combineReducers({
    users,
    enterprises,
    teams
  });

export default Hmireducer;