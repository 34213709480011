import React, { Component } from 'react'
import Pageheader from '../../components/pageheader';
import { ClipLoader } from 'react-spinners';
import { Trans, withI18n } from "react-i18next";
import moment from 'moment';
import SVGSearchIcon from "../../components/svgIcons/search";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ENVIRONMENTS }  from '../../environment';

const log_table =  ENVIRONMENTS.dynamoDB['logs'];
const limit = 20;
class Logs extends Component {
    constructor() {
        super();
        this.state = {
            logs: [],
            progress: false,
            LastEvaluatedKey: null,
            loadmore: false,
            searchvalue: '',
            loading: true,
            ScanIndexForward: false
        }
        this.profile = {}
    }

    componentDidMount() {
        this.profile = this.props.profile;
        this.getLogs();

    }

    getLogs = () => {
        this.setState({ loading: true });
        let params = {
            TableName: log_table,
            Limit: limit,
            ScanIndexForward: this.state.ScanIndexForward
        };

        if (this.state.searchvalue) {
            params['IndexName'] = 'user-index';
            params['KeyConditions'] = {
                email: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [
                        this.state.searchvalue.trim()
                    ]
                }
            }
        } else {
            params['IndexName'] = "sorting-index"
            params['KeyConditions'] = {
                filedtype: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: [
                        "sort"
                    ]
                }
            }
        }
        if (this.state.LastEvaluatedKey)
            params['ExclusiveStartKey'] = this.state.LastEvaluatedKey;
        this.profile.docClient.query(params, this.callbackQuery);
    }

    callbackQuery = (err, data) => {
        if (err) {
            console.error("Unable to scan the table. Error JSON:", JSON.stringify(err, null, 2));
        } else {
            let logs = this.state.logs.concat(data.Items);
            if (typeof data.LastEvaluatedKey != "undefined") {
                this.setState({
                    logs: [...logs],
                    LastEvaluatedKey: data.LastEvaluatedKey,
                    loading: false
                });
            } else {
                this.setState({
                    logs: [...logs],
                    LastEvaluatedKey: null,
                    loading: false
                });
            }
        }
    }

    searchHandleChange = (event) => {
        this.setState({ searchvalue: event.target.value }, () => {
            if (!this.state.searchvalue)
                this.searchUsername();
        });
    }

    searchKeyPress = (event) => {
        if (event.key === 'Enter' && this.state.searchvalue) {
            this.searchUsername();
        }
    }

    sortIconClick = () => {
        this.setState({
            ScanIndexForward: !this.state.ScanIndexForward,
            LastEvaluatedKey: null,
            logs: []
        }, () => {
            this.getLogs();
        });
    }

    searchUsername = () => {
        this.setState({
            LastEvaluatedKey: null,
            logs: []
        }, () => {
            this.getLogs();
        });
    }

    render() { 
        let { ScanIndexForward } = this.state;
        let sortIcon = 'sort-up';
        sortIcon = !ScanIndexForward ? 'sort-up' : 'sort-down';

        return (
            <div className="content-wrapper">
                <Pageheader title={<Trans>Users Logs</Trans>} />
                <div className="searcharea" style={{ marginLeft: 'auto', display: 'flex', color: '#429ad6' }}>
                    <div style={{ width: '300px', marginLeft: '2px' }}>
                        <input
                            type="search"
                            className="form-control"
                            placeholder={this.props.t('Search by username')}
                            value={this.state.searchvalue}
                            onKeyDown={this.searchKeyPress}
                            style={{ height: '39px' }}
                            onChange={this.searchHandleChange}
                        />
                        <SVGSearchIcon /> 
                    </div>
                    <button className="btn btn-primary" style={{ borderRadius: '0'}} type="button" onClick={this.searchUsername}><Trans>Search</Trans></button>
                </div>
                <br />
                <div>
                    {this.state.logs.length > 0 ? (<table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col"><Trans>Table</Trans></th>
                                <th scope="col"><Trans>username</Trans></th>
                                <th scope="col" onClick={this.sortIconClick}><Trans>Date</Trans>
                                    <span className="sort-icon">
                                        <FontAwesomeIcon
                                            icon={['fas', sortIcon]}
                                            fixedWidth={false}
                                            size="1x"
                                        />
                                    </span>
                                </th>
                                <th scope="col"><Trans>Action</Trans></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.logs.map((log, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="capitalize">
                                            {log.table}
                                        </td>
                                        <td>
                                            {log.email}
                                        </td>
                                        <td>
                                            {moment.utc(log.createdAt).local().format("YYYY/MM/DD HH:mm A")}
                                        </td>
                                        <td>
                                            <pre> {log.action}</pre>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>) : (<div className="w-100 text-center alert alert-custom">
                        {this.state.loading ? <span><Trans>Loading...</Trans></span> : <span><Trans>No data found</Trans></span>}
                    </div>)}
                </div>
                {(this.state.LastEvaluatedKey) && (
                    <div className="text-center loadmorewrapper">
                        {this.state.loading ?
                            (
                                <ClipLoader
                                    className="clipLoader"
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#fff'}
                                    loading={this.state.loading}
                                />
                            ) : (
                                <button className="load-more btn" onClick={this.getLogs}>
                                    <Trans>loadMore</Trans>
                                </button>
                            )}
                    </div>
                )}
            </div>
        );
    }
}

export default withI18n()(Logs);