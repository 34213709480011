import { SWITCH_USER } from './actions';

function profile(state={}, action) {

  switch(action.type) {
    case SWITCH_USER:     
      return action.user;
    default:
      return state;
  }
}


export default profile;