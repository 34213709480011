import Amplify from 'aws-amplify';
import aws_exports from '../aws-exports';

const amplifyAutu = () => {
    Amplify.configure(aws_exports);

    // Amplify.configure({
    //     Auth: {
    
    //         identityPoolId: 'us-east-2:9785e13e-5e09-41bb-8c93-df27f9ffa15f',
    //         region: 'us-east-2',
    //         identityPoolRegion: 'us-east-2',
    //         userPoolId: 'us-east-2_YxLjMP78G',
    //         userPoolWebClientId: '2uh5k82hfketvbd8r2defh37jv',
    //         mandatorySignIn: false        
    //     }
    // });
    }

export default amplifyAutu;

