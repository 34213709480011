import React, { Component } from 'react'
import { NavLink  } from "react-router-dom";
import Can from "./can";
import { Trans } from "react-i18next";
import './navigation.scss';

class Navigation extends Component {

    state = {
        slide: false
    }

    slideNavigation = () => {
        this.setState((prevState) => {
            return {
                slide: !prevState.slide 
            }
          })          
    }
    usersepage = (match, location) => {
       return (match.isExact || location.pathname === "/manageusers")
    }
    render() {
            let slideclass = '';
            if(this.state.slide) {
                slideclass = 'slide';
            }
            const {group} = this.props;
        return (
            <div className={slideclass}>
            <Can
            role={group}
            perform="navigation:visit"
            yes={() =>  (
                <nav className="sidebar">
                    <div className="text-right">
                        <button className="btn navbtn" onClick={this.slideNavigation}> </button>
                    </div>
                    <ul>                    
                        <Can
                            role={group}
                            perform="nav:users"
                            yes={() =>  (
                            <li>
                                <NavLink exact className="link-user" to="/"><Trans>MANAGE</Trans>  <br /> <Trans>USERS</Trans></NavLink >
                            </li>
                            )}
                         />

                        <Can
                            role={group}
                            perform="nav:enterprise"
                            yes={() =>  (
                            <li>
                                <NavLink exact className="link-enterprises" to="/manageenterprises"><Trans>MANAGE</Trans> <br /> <Trans>ENTERPRISES</Trans></NavLink >
                            </li>
                            )}
                        />

                          <Can
                            role={group}
                            perform="nav:teams"
                            yes={() =>  (
                        <li>
                            <NavLink exact className="link-teams" to="/manageteams"><Trans>MANAGE</Trans>  <br /> <Trans>TEAMS</Trans></NavLink >
                        </li>
                            )}
                            />
                        <Can
                            role={group}
                            perform="nav:logs"
                            yes={() =>  (
                        <li>
                            <NavLink exact className="link-teams" to="/logs"><Trans>ACTIVITY</Trans> <br /> <Trans>LOGS</Trans></NavLink>
                        </li>
                            )}
                            />
                    </ul>
                </nav>
            )}
            
            />
            </div>
        )

    }
}

export default Navigation;