import { ENVIRONMENTS }  from '../../../environment';
import signedin from '../signedinUser';
import { writeUserActivityLog } from '../../../utils/utilityfunctions';

export const FETCH_TEAMS_BEGIN = 'FETCH_TEAMS_BEGIN';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_LOADMORE_TEAMS_BEGIN = 'FETCH_LOADMORE_TEAMS_BEGIN';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const START_EDIT = 'START_EDIT';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';
export const RESET_EDIT = "RESET_EDIT"


const teamtable = ENVIRONMENTS.dynamoDB['teams'];
const size = 20;

export const fetchTeamsBegin = () => ({
  type: FETCH_TEAMS_BEGIN
});

export const fetchLoadmoreTeamsBegin = () => ({
  type: FETCH_LOADMORE_TEAMS_BEGIN
});

export const fetchTeamSuccess = teams => ({
  type: FETCH_TEAMS_SUCCESS,
  payload: teams
});

export const startEdit = item => ({
  type: START_EDIT,
  payload: item
})

export const resetEdit = () => ({
  type: RESET_EDIT
})

export const updateTeamSuccess = item => ({
  type: UPDATE_TEAM_SUCCESS,
  payload: item
})

export const createTeamSuccess = team => ({
  type: CREATE_TEAM_SUCCESS,
  payload: team
});

export const deleteTeamSuccess = ids => ({
  type: DELETE_TEAM_SUCCESS,
  payload: ids
});

export const updateStatusSucess = ids => ({
  type: UPDATE_STATUS_SUCCESS,
  payload: ids
})

export const unexpectedError = () => ({
  type: UNEXPECTED_ERROR
});


export function fetchTeams({ loadmore = false, status } = {}) {
  return (dispatch, getState) => {
    if (!loadmore) 
      dispatch(fetchTeamsBegin());
    else 
      dispatch(fetchLoadmoreTeamsBegin());
      
      const prevState = getState().teams;

      const fetchParams = {
        TableName: teamtable,
        Limit: size
      }
    if (loadmore && prevState.LastEvaluatedKey)
      fetchParams.ExclusiveStartKey = prevState.LastEvaluatedKey;
    
      if(!status)
        status = prevState.status;

      const params = {
      ...fetchParams,
      IndexName: 'tstatus-index',
      KeyConditionExpression: 'tStatus = :s',
      ExpressionAttributeValues: { ':s': status }
    }
    signedin.profile.docClient.query(params,
      (err, data) => {
        if (err) {
          dispatch(unexpectedError(err.message));
        }
        if (data) {
          let items = data.Items;
          if (loadmore) {
            const oldItems = prevState.items;
            items = [...oldItems, ...items];
          }
          const retData = {
            items,
            LastEvaluatedKey: data.LastEvaluatedKey,
            status: status
          }
          dispatch(fetchTeamSuccess(retData));
        }
      });
  };
}

export function createTeam(item) {

  return (dispatch, getState) => {      
    
    const result = new Promise((resolve, reject) => {

      signedin.profile.docClient.put({
        TableName: teamtable,
        Item: item
    }, (err, data) => {
      if(err) {
        resolve({status: err.message});
      } else {
        dispatch(createTeamSuccess(item));
        resolve({status: 'success'});
        writeUserActivityLog('Team created : ' + item.tName , 'team');
      }
    })

    })
    
      result.then((data) => {          
      })
      return result;

}

}

export function updateTeam(item){
  return (dispatch, getState) => {
    const result = new Promise( (resolve, reject ) => {
      let expressionAttrName = {
        "#tn": "tName",
        "#tinfo":"tInfo",
        "#name":'name'
    }

    let expressionAttrValues = {
        ":tn": item.tName,
        ":name": item.tInfo.name
    }
    let UpdateExpression = 'set #tn = :tn, #tinfo.#name=:name';

    if(item.tInfo.owner) {
        expressionAttrName["#owner"] = 'owner';        
        UpdateExpression += ',#tinfo.#owner=:owner';
        expressionAttrValues[":owner"] = item.tInfo.owner;
    }

    if(item.enterprise) {
      expressionAttrName["#enterprise"] = 'enterprise';        
      UpdateExpression += ',#enterprise=:enterprise';
      expressionAttrValues[":enterprise"] = item.enterprise;
   
      expressionAttrName["#ent"] = 'enterprise';        
      UpdateExpression += ',#tinfo.#ent=:ent';
      expressionAttrValues[":ent"] = item.tInfo.enterprise;
   
    }
 
    signedin.profile.docClient.update({
        TableName: teamtable,
        Key: {
            id: item.id
        },
        UpdateExpression: UpdateExpression,
        ExpressionAttributeNames: expressionAttrName,
        ExpressionAttributeValues: expressionAttrValues,
      }, (err, data) => {
        if(err) {
          resolve({status: err.message});
        }else {         
          dispatch(updateTeamSuccess(item));          
          resolve({status: 'success'});
          writeUserActivityLog('Team updated : ' + item.tName , 'team');

        }
      })
    })     
    return result;
  }
}

export function deleteTeams(ids){

  return (dispatch, getState) => {

    const deleteObject = ids.reduce( (accumulator, id) => {  
     
      accumulator.push(
          {
            DeleteRequest: {
              Key: {
              id: id                                                      
              }
            }
          } 
        ) 
        return accumulator;          
    }, []);

    return new Promise((resolve, reject) => {
          
        var params = {
          RequestItems: {
          [teamtable]: deleteObject
          }
        };
        signedin.profile.docClient.batchWrite(params, (err, data) => {
          if (err) {
            resolve({error:err.message})
          } 
          else    {
            const teams = getState().teams.items;
            const teamnames = teams.filter(team => ids.includes(team.id))
                              .map(team => team.tName)
            writeUserActivityLog(`Teams Deleted: ${JSON.stringify(teamnames)}`, 'team');
                              
            dispatch(deleteTeamSuccess({ids}));
          }  
          resolve()         
        });         
    })

  }
}

export function updateStatus(ids, status) {

  return (dispatch, getState) => {    
    let result = ids.reduce( (accumulatorPromise, id) => {  
      return accumulatorPromise.then(() => {        
        return updateTeamAsync(id, status);
      })         
    }, Promise.resolve());
    result.then(() => { 
      const teams = getState().teams.items;
      const teamnames = teams.filter(team => ids.includes(team.id))
                        .map(team => team.tName)
      writeUserActivityLog(`Teams Updated: ${JSON.stringify(teamnames)}`, 'team');
          dispatch(updateStatusSucess({ids}));               
    })
    return result;

    function updateTeamAsync(id, status) {
      return new Promise((resolve, reject) => {      
          signedin.profile.docClient.update({
            TableName: teamtable,  
            Key: {
                id: id
            },
            UpdateExpression: 'set #s = :s',
            ExpressionAttributeNames: {
                "#s": "tStatus"
            },
            ExpressionAttributeValues: {
                ":s": status
            }              
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve();
            }
        });

      })
    }
  }

}