import { Auth, Hub } from 'aws-amplify';
import AWS, { DynamoDB } from 'aws-sdk';
import {isEmpty} from 'lodash';
import { switchUser } from './actions';
import { ENVIRONMENTS }  from '../../environment';

export default class AmplifyBridge {
  constructor(store) {
    this.store = store;
    this.usertable = ENVIRONMENTS.dynamoDB['users'];
    Hub.listen('auth', this, 'Amplifybridge');  
    this.checkUser();
  }

  onHubCapsule(capsule) {
    const event = capsule.payload.event;
    if(event === 'signIn' || event === 'signOut') {
      this.checkUser();
    }
  }

  checkUser() {
    Auth.currentAuthenticatedUser()
    .then(user => this.loadUserInfo(user))
    .catch(err => this.checkUserError(err));   
  }

  loadUserInfo(user) {
    Auth.currentUserInfo()
      .then(info => this.loadUserInfoSuccess(user, info))
      .catch(err => this.checkUserError(user, err));
  }

  checkUserError(err) {
    this.store.dispatch(switchUser(null));
    //this.store.dispatch(deleteUser());
  }

  loadUserInfoSuccess(user, info) {  
    Auth.currentUserCredentials()
    .then(credentials => { 
      let cred = Auth.essentialCredentials(credentials);
      this.loadUserCredentialSuccess(user, info, cred) 
    })
    .catch(err => this.checkUserError(user, err));

    
  }

  loadUserCredentialSuccess(user, info, credentials) {

    const docClient= new DynamoDB.DocumentClient({
      credentials: credentials
     });
    const cognitoidentity= new AWS.CognitoIdentityServiceProvider({
      credentials: credentials
    });  

    const credConfig = {
      docClient,
      cognitoidentity
    }

    docClient.get({
      TableName: this.usertable,
      Key: {
        id: credentials.identityId
        }
      }, (err, data) => {
        if(err) {
          Auth.signOut()
            .then(data =>{
              this.store.dispatch(switchUser({error:err.message}));
            })
           
        } else {   
          if(!isEmpty(data)) {
            let userSession = user.signInUserSession.idToken.payload;

            const userInfo = {...user, ...info, ...data.Item, ...credConfig, group: userSession['cognito:groups'][0]}          
            this.store.dispatch(switchUser(userInfo));
          } else {
            Auth.signOut()
            .then(data =>{
              this.store.dispatch(switchUser({error: "User not exists in our record"}));
            })
        }    
      }
    })
  }
}
