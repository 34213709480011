import React, { Component } from 'react'
import {connect} from 'react-redux';
import Pageheader from '../../components/pageheader';
import Navtab from '../../components/navtab';
import { Trans, withI18n } from "react-i18next";
import { fetchTeams } from '../../store/appdata/team/teamActions';
import Form from './components/form';
import Table from './components/table';

class ManageTeams extends Component {
   
    componentDidMount() {
        this.props.fetchTeams();
    }

    render() {
        return (
            <div className="content-wrapper">
                {/* <Alert stack={true} timeout={3000} /> */}
                
                <Pageheader title={<Trans>manageTeams</Trans>} />

               
                <div className="row no-gutters">
                    {!this.props.editedItem ? (
                        <Navtab tabs={[{ id: 1, name: <Trans>addTeam</Trans> }]} />
                    ): (
                         <Navtab tabs={[{ id: 2, name: <Trans>Update Team</Trans> }]} />
                    )}
                </div>

                <Form />
                <Table 
                    tabs={[{ id: 1, name: 'Team' }]}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    editedItem: state.teams.editedItem
})
const mapDispatchToProps = {
    fetchTeams
};

export default connect(mapStateToProps, mapDispatchToProps)(withI18n()(ManageTeams));