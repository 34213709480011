const rules = {
   
    SuperAdmin: {
        static: [
            "group: SuperAdmin, SystemAdmin, EnterpriseAdmin, TeamAdmin, Champion, User",
            "users:list",
            "users:create",
            "users:edit",
            "users:delete",
            "teams:list",
            "teams:create",
            "teams:edit",
            "teams:delete",
            "enterprise:list",
            "enterprise:create",
            "enterprise:edit",
            "enterprise:delete",
            "nav:users",
            "nav:enterprise",
            "nav:teams",
            "nav:logs",
            "navigation:visit"
          ]
      },

    SystemAdmin: {
        static: [
            "group: SystemAdmin, EnterpriseAdmin, TeamAdmin, Champion, User",
            "users:list",
            "users:create",
            "users:edit",
            "users:delete",
            "teams:list",
            "teams:create",
            "teams:edit",
            "teams:delete",
            "enterprise:list",
            "enterprise:create",
            "enterprise:edit",
            "enterprise:delete",
            "nav:users",
            "nav:enterprise",
            "nav:teams",
            "navigation:visit"
          ]
    },
     
    EnterpriseAdmin: {
        static: [
            "group: EnterpriseAdmin, TeamAdmin, Champion, User",
            "users:list",
            "users:create",
            "users:edit",
            "users:delete",
            "teams:list",
            "teams:create",
            "teams:edit",
            "teams:delete",
            "enterprise:list",
            "enterprise:create",
            "enterprise:edit",
            "enterprise:delete",
            "nav:users",
            "nav:enterprise",
            "nav:teams",
            "navigation:visit"
          ]
    },
    TeamAdmin: {
        static: [
            "group: Champion, User",
            "users:list",
            "users:create",
            "users:edit",
            "users:delete",
            "teams:list",
            "teams:create",
            "teams:edit",
            "teams:delete",
            "nav:users",
            "nav:teams", 
            "navigation:visit"         
          ]
      },

    Champion: {
        static: [
            "group: User",
            "users:list",
            "users:create",
            "users:edit",
            "users:delete",
            "nav:users",
            "navigation:visit"
        ]
      },
      user: {
        static: ["profile:view"]
      }

  };
  
  export default rules;