import React from 'react';
import AsyncPaginate from "react-select-async-paginate";
import { isEmpty } from 'lodash';
import { ENVIRONMENTS }  from '../../environment';
import signedin from '../../store/appdata/signedinUser';

export class EnterpriseAutoComplete extends React.Component {
    constructor(props) {
        super(props);      
        this.requestTimer = null;
        this.lastEvaluatedKey = null;
        this.enterprisetable = ENVIRONMENTS.dynamoDB['enterprises'];      
    }  
    static defaultProps = {
        IndexName: 'typeahead-index',
        IndexKey: 'tabletype',
        KeyValue: 'enterprise'
    }
    getEnterprises = (val) => {
        const profile = signedin.profile;
        let promise = new Promise(resolve => {
            let params =  {
                TableName: this.enterprisetable,
                IndexName: this.props.IndexName,
                ExpressionAttributeValues: { ':s': this.props.KeyValue},
                KeyConditionExpression: `${this.props.IndexKey} = :s`,
                Limit: 20   
            }
            if(this.lastEvaluatedKey)
                params['ExclusiveStartKey'] = this.lastEvaluatedKey.id;
            if(val) {
                params['KeyConditionExpression'] = `${params['KeyConditionExpression']} AND begins_with(eName, :string)`;
                params.ExpressionAttributeValues[':string'] = val.toLowerCase();
            }
            profile.docClient.query(params, (err, data) => {
                if (err) {
                    console.log(err);          
                } else {    
                    resolve(data);                    
                }
            })
        });
        return promise;
    }

    typeTrigger = async(val, prevOptions) => {
        const enterprises = await this.getEnterprises(val);
       if(isEmpty(enterprises)) {
            return {
                options: [],
                hasMore: false
              };
           }
       this.lastEvaluatedKey = enterprises.LastEvaluatedKey ? enterprises.LastEvaluatedKey : null;
        return {
            options: enterprises.Items,
            hasMore: !!this.lastEvaluatedKey
          };  
    }
    render() {
      return (              
                <AsyncPaginate
                    value={this.props.value ? this.props.value : null}
                    loadOptions={this.typeTrigger}
                    placeholder='Enterprise'
                    onChange={(event) => {this.props.onChange(event, 'enterprise')}}
                    getOptionLabel={(option) => option.eInfo.name}
                    getOptionValue={(option) => option.id}
                    className={this.props.classes}
                    classNamePrefix={this.props.prefixClass}
                    isClearable={this.props.isClearable}
                />
        );
    }
}
