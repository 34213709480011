import {
    FETCH_USERS_BEGIN,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    FILTER_USERS_BEGIN,
    FETCH_LOADMORE_BEGIN,
  } from './fetchActions';
  
  import { 
    CREATE_USER_SUCCESS,    
    START_USER_EDIT,
    RESET_EDIT,
    UPDATE_USER_SUCCESS,
    DELETE_USER_SUCCESS,
    UPDATE_USER_STATUS,      
  } from './updateActions'


  const initialState = {
    items: [],
    loading: false,
    error: null,
    LastEvaluatedKey: null,
    showmore: false,
    editedItem: null,
    filterprogress: false,
    loadmoreinprogress: false,
    filtered: false,
    formloading: false,
    query: {
        status: null,
        enterprise: null,
        team: null,
        searchStr: null,
        sort: null,
        pagination: {
            pagenumber: 1,
            size:20
        }      
    }
  };

  
  export default function userReducer(state = initialState, {type, payload}) {
    switch(type) {
      case FETCH_USERS_BEGIN:
        return {
          ...state,
          filtered: false,
          loading: true,
          showmorebtn: false,
          LastEvaluatedKey: null,
          query: {...initialState.query},
          error: null,
          editedItem: null
        };
      case FILTER_USERS_BEGIN:
        return {
          ...state,
          filtered: true,
          loading: true,
          LastEvaluatedKey: null,
          filterprogress: true,
          error: null,
          editedItem: null
        };
      case FETCH_LOADMORE_BEGIN:
        return {
          ...state,
          loadmoreinprogress: true
        }
        
      case FETCH_USERS_SUCCESS:
        return {
          ...state,
          filterprogress: false,
          loadmoreinprogress: false,
          loading: false,
          ...payload
        };
  
      case FETCH_USERS_FAILURE:
        return {
          ...state,
          error: payload.error,
          items: []
        };

      case CREATE_USER_SUCCESS:
        if(!state.query.value || state.query.value === payload.userStatus) {
          let updatedItems = [payload, ...state.items];
          return {
            ...state,
            items: updatedItems    
          };
        } 
        return {
          ...state            
        };

        case START_USER_EDIT:
        return {
          ...state,
          editedItem: payload,
        };

        case RESET_EDIT: 
        return {
          ...state,
          editedItem: null
        }

        case UPDATE_USER_SUCCESS: {
          const updatedItems = [...state.items];
          const itemIndex = updatedItems.findIndex(item => payload.id === item.id);
          updatedItems[itemIndex] = payload;

          return {
            ...state,
            editedItem: null,
            items: updatedItems
          }
        }

        case DELETE_USER_SUCCESS: {
          const updatedItems = state.items.filter(item => !payload.userids.includes(item.id));          
          return {
            ...state,
            items: updatedItems
          }
        }

        case UPDATE_USER_STATUS: {          
          const updatedItems = state.items.filter(item => !payload.userids.includes(item.id));                    
          return {
            ...state,
            items: updatedItems
          }
        }

      default:
        return state;
    }
  }