import moment from 'moment'
import { ENVIRONMENTS }  from '../../../environment';
import { writeUserActivityLog } from '../../../utils/utilityfunctions';
import signedin from '../../../store/appdata/signedinUser';

const uuidv1 = require('uuid/v1');

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
//export const  CREATE_USER_ERROR = 'CREATE_USER_ERROR';
export const  START_USER_EDIT = 'START_USER_EDIT';
export const RESET_EDIT = 'RESET_EDIT';
export const  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const  UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const  UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';

export const usertable = ENVIRONMENTS.dynamoDB['users'];
// const DELETE_USER = 'DELETE_USER';

export function createUserSuccess(item) {
    return {
      type: CREATE_USER_SUCCESS,
      payload: item
    }
  }

// export function createUserError(message) {
//   return {
//     type: CREATE_USER_ERROR,
//     payload: message
//   }
// }

export function startUserEdit(item) {
  return {
    type: START_USER_EDIT,
    payload: item
  }
}

export const resetEdit = () => ({
  type: RESET_EDIT
})

export function updateUserSuccess(item) {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: item
  }
}

export function deleteUserSuccess(item) {
  return {
    type: DELETE_USER_SUCCESS,
    payload: item
  }
}

export function updateStatusSucess(item) {
  return {
    type: UPDATE_USER_STATUS,
    payload: item
  }
}

export function updateUserFailure(item) {
  return {
    type: UPDATE_USER_FAILURE,
    payload: item
  }
}

export function createUsers(user) {
    const profile = signedin.profile;
    return (dispatch, getState) => {      
        const result = createUserAsync(user);
        result.then( data => {          
          dispatch(createUserSuccess(user.item));
        })        
        return result;
     };
       
     function createUserAsync(user) {
      return new Promise((resolve, reject) => {
        let {item, role} = user;
    
        checkUserExists(item.email, ({error} = {}) => {                
            if(error) {
              reject(error);
            }
            else {
                if(role === 'SuperAdmin') {
                  createCognitoUser(item, role, ({item, error}) => {
                    if(error) {
                      reject(error); 
                    } else {
                      item.userStatus = 'active';
                      createUserInDb(item, ({error} = {}) => { 
                        if(error) 
                          reject(error);                  
                        else 
                          resolve({status:'success', item});
                      })
                    }
                  })
                }
                else {
                  item['id'] =  uuidv1();
                  item.userStatus = 'new';
                  createUserInDb(item, ({error} = {}) => {
                  if(error) 
                    reject(error);                  
                  else 
                   resolve({status:'success', item});
                });
    
                }
            }
        })  
      });
    }
    
    function createCognitoUser(item, role, callback) {
      const poolid = profile.pool.userPoolId;
      let params = {
        UserPoolId: poolid,
        Username: item.email,
        DesiredDeliveryMediums: [
        'EMAIL',
        ],          
        UserAttributes: [
        {
          Name: 'email',
          Value: item.email
        },
        {
            Name: 'email_verified',
            Value: 'True'
        },
        {
            Name: 'name',
            Value: item.userInfo.firstName
        }
              
        ]
        };
        profile.cognitoidentity.adminCreateUser(params, (err, data) => {
        if (err) {
            callback({error: err.message});
        } 
          else   {
            item['id'] = `unconfirmed-${data.User.Username}`;
            item.userInfo['userName'] = data.User.Username;        
            callback({item});
            var params = {
                GroupName: role,
                UserPoolId: poolid,
                Username: data.User.Username
              };
              profile.cognitoidentity.adminAddUserToGroup(params, (err, groupdata) => {         
              });
          }         
        });     
        
    }
    
    function createUserInDb(item, callback) {
      
      profile.docClient.put({
        TableName: usertable,
        Item: item,
        }, (err, dbdata) => {
          if(err) {
              callback({error: err.message});
        }
        else {
          callback();
          writeUserActivityLog('User created : '+ item.email, 'user');
        }
      })
    }
    
    
    function checkUserExists(email, callback) {    
        const params = {
            TableName: usertable,
            IndexName: 'email-index',
            KeyConditionExpression: 'email = :e',
            ExpressionAttributeValues: {
                ":e": email
            }
        }
    
        profile.docClient.query(params, (err, data) => {
            if (err) {
                return callback({error: err.message });
            } else {
                if (data.Items.length === 0)
                    return callback();
                else if (data.Items.length > 0)
                return callback({error: 'User exists in our record' });
            }
        });
    }
  }

export function  deleteUsers(users) {
  const profile = signedin.profile;
  return (dispatch, getState) => {
    const deleteObject = [];
    const userids = [];
    let result = users.reduce( (accumulatorPromise, user) => {  
     
      return accumulatorPromise.then(() => {
        deleteObject.push(
          {
            DeleteRequest: {
              Key: {
              id: user.id                                                      
              }
            }
          } 
        )
        userids.push(user.id);
        return deleteUserAsync(user);
      })         
    }, Promise.resolve());

    return new Promise((resolve, reject) => {
      result.then(() => {       
        var params = {
          RequestItems: {
          [usertable]: deleteObject
          }
        };
        profile.docClient.batchWrite(params, (err, data) => {
          if (err) {
            reject(err.message);
          } 
          else    {            
            dispatch(deleteUserSuccess({userids}));
            resolve() 
            const emails = users.map(user => user.email)
            writeUserActivityLog(`Users Deleted: ${JSON.stringify(emails)}`, 'user' );
          }  
                  
        });         
      })
    })

    function deleteUserAsync(user) {
      return new Promise((resolve, reject) => {
        const poolid = profile.pool.userPoolId;
        const username = user.userInfo.userName;
        if(username)   {         
          var params = {
              UserPoolId: poolid,
              Username: username
          };
          profile.cognitoidentity.adminDeleteUser(params, (err, data) => {
            resolve();
          });      
        } else {
          resolve();
        }
      })
    }
  }
}


export function updateUser({item, role, prevrole}) {
  return (dispatch, getState) => {
    const result = new Promise( (resolve, reject ) => {
        const {userName} = item.userInfo;
        if(userName && role !== prevrole) {
            updateUserinCognito(userName, role, prevrole, (error) => {
              if(error)
                reject(error);
              else {
                updateUserInDB(item, ({error} = {}) => {
                  if(error) 
                    reject(error);                  
                  else  {
                    resolve({status:'success', item});                    
                  }                 
                }); 
              }
            });
          
                                               
        } 
      else {
        updateUserInDB(item, ({error} = {}) => {
          if(error) 
            reject(error);                  
          else 
           resolve({status:'success', item});
        });
      }
    });
    result.then(response => {
      dispatch(updateUserSuccess(response.item));
    })     
    return result;
  }
                                 
}

function updateUserinCognito(userName, role, prevrole, callback){

  const profile = signedin.profile;

  const poolid = profile.pool.userPoolId;

  var params = {
      GroupName: prevrole,
      UserPoolId: poolid,
      Username: userName
    };
    profile.cognitoidentity.adminRemoveUserFromGroup(params, (err, data) => {
      if (err) {
        callback(err.message);                         
      }
      else  {   
        params.GroupName = role;
        profile.cognitoidentity.adminAddUserToGroup(params, (err, data) => {
           if(err)
              callback(err.message)
            else 
              callback();
        });
      }
  });
}

function updateUserInDB(user, callback) {
    const profile = signedin.profile;
    let UpdateExpression = 'set #a.#f = :f, #a.#l = :l, #e = :e, #r = :r,#fn = :fn, #a.#date= :date';
    let ExpressionAttributeNames= {
      "#a": "userInfo",
      "#f": "firstName",
      "#l": "lastName",
      "#e": "email",
      "#r": "userRole",
      "#fn": "fullName",
      "#date": "updatedAt"   
    };
    let ExpressionAttributeValues= {
      ":f": user.userInfo.firstName,
      ":l": user.userInfo.lastName, 
      ":e": user.email, 
      ":r": user.userRole,  
      ":fn": user.fullName,
      ":date": user.userInfo.updatedAt                  
    }

    if(user.teamId) {
        UpdateExpression += ',#teamId=:tid';
        ExpressionAttributeNames['#teamId'] = 'teamId';
        ExpressionAttributeValues[':tid'] = user.teamId;
        UpdateExpression += ',#a.#team=:tname';
        ExpressionAttributeNames['#team'] = 'team';
        ExpressionAttributeValues[':tname'] = user.userInfo.team;
    }
    if(user.enterpriseId) {
        UpdateExpression += ',#enterpriseId=:eid';
        ExpressionAttributeNames['#enterpriseId'] = 'enterpriseId';
        ExpressionAttributeValues[':eid'] = user.enterpriseId;
        UpdateExpression += ',#a.#enterprise=:ename';
        ExpressionAttributeNames['#enterprise'] = 'enterprise';
        ExpressionAttributeValues[':ename'] = user.userInfo.enterprise;
    }
    // if(user.userName) {
    //     UpdateExpression += ',#a.#userName=:userName';
    //     ExpressionAttributeNames['#userName'] = 'userName';
    //     ExpressionAttributeValues[':userName'] = user.userName;
    // }

    profile.docClient.update({
        TableName: usertable,      
        Key: {
            id: user.id
        },
        UpdateExpression: UpdateExpression,
        ExpressionAttributeNames: ExpressionAttributeNames,
        ExpressionAttributeValues: ExpressionAttributeValues    
    }, (err, data) => {

    if(err) {
      
    } else {
        callback();
        writeUserActivityLog('User updated : '+ user.email, 'user');

    }
    });
}

export function updateStatus(users, tostatus) {

  const profile = signedin.profile;
  return (dispatch, getState) => {    
    const userids = [];
    let result = users.reduce( (accumulatorPromise, user) => {  
      return accumulatorPromise.then(() => {        
        userids.push(user.id);
        return updateUserAsync(user, tostatus);
      })         
    }, Promise.resolve());
    result.then(() => { 
          dispatch(updateStatusSucess({userids})); 
          const emails = users.map(user => user.email)
          writeUserActivityLog(`Users Status changed to ${tostatus} : ${JSON.stringify(emails)}`, 'user' );              
    })
    return result;

    function updateUserAsync(user, tostatus) {
      return new Promise((resolve, reject) => {
        const poolid = profile.pool.userPoolId;
        const username = user.userInfo.userName;
        if(username)   {         
          const  process = tostatus === 'inactive' ? 'adminDisableUser' : 'adminEnableUser';
          var params = {
            UserPoolId: poolid,
            Username: username
          };
          profile.cognitoidentity[process](params, (err, data) => {});     
        } 
          profile.docClient.update({
            TableName: usertable,  
            Key: {
                id: user.id
            },
            UpdateExpression: 'set #s = :s, #u.#date = :date',
            ExpressionAttributeNames: {                                                                    
                "#s": "userStatus",
                "#u":'userInfo',
                "#date": "updatedAt"
            },
            ExpressionAttributeValues: {                                        
                ":s": tostatus,
                ":date": moment().utc().format()
            }                
        }, (err, data) => {
            if (err) {
                reject(err);
            } else {
                resolve();
            }
        });

      })
    }
  }

}
