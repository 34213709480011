import React from 'react';
import AsyncPaginate from "react-select-async-paginate";
import { isEmpty } from 'lodash';
import { ENVIRONMENTS }  from '../../environment';
import signedin from '../../store/appdata/signedinUser';

export class UserAutoComplete extends React.Component {
    constructor() {
        super();
        this.state = {
            user: null
        };
        this.requestTimer = null;
        this.lastEvaluatedKey = null;
        this.usertable = ENVIRONMENTS.dynamoDB['users'];
    }   

    getTeams = (val) => {
        let promise = new Promise(resolve => {         
            let params =  {
                TableName: this.usertable,
                IndexName: 'status-name',
                ExpressionAttributeValues: { ':s': 'active'},
                KeyConditionExpression: 'userStatus = :s',
                Limit: 20   
            }
            if(this.lastEvaluatedKey)
                params['ExclusiveStartKey'] = this.lastEvaluatedKey;
            if(val) {
                params['KeyConditionExpression'] = `${params['KeyConditionExpression']} AND begins_with(fullName, :string)`;
                params.ExpressionAttributeValues[':string'] = val.toLowerCase();
            }

            signedin.profile.docClient.query(params, (err, data) => {
                if (err) {
                    console.log(err);          
                } else {    
                    resolve(data);                    
                }
            })
        });
        return promise;
    }

    typeTrigger = async(val, prevOptions) => {
        const teams = await this.getTeams(val);
       if(isEmpty(teams)) {
            return {
                options: [],
                hasMore: false
              };
           }
       
       this.lastEvaluatedKey = teams.LastEvaluatedKey ? teams.LastEvaluatedKey : null;
        return {
            options: teams.Items,
            hasMore: !!this.lastEvaluatedKey,
          };  
    }

    render() {
      return (
            <div className="position-relative">              
                <AsyncPaginate
                    value={this.props.value}
                    loadOptions={this.typeTrigger}
                    placeholder='Owner'
                    onChange={(event) => {this.props.onChange(event, 'owner')}}
                    getOptionLabel={(option) => `${option.userInfo.firstName} ${option.userInfo.lastName ? option.userInfo.lastName: ''}`}
                    getOptionValue={(option) => option.id}
                    className={this.props.classes}
                    classNamePrefix={this.props.prefixClass}                 
                    isClearable={this.props.isClearable}
                />
            </div>
        );
    }
}
