import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import Hmireducer from './store/appdata/HmiReducers'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './scss/custom.scss';
import './index.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import i18n from "./utils/i18n";

const store = createStore(
    Hmireducer,
    applyMiddleware(thunk)
    )

ReactDOM.render(
    <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
    </Provider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
