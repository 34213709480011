import React, { Component } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Navtab from './navtab';
import moment from 'moment'
import Select from 'react-select';
import { Trans } from "react-i18next";

const dropdown = {
    Enterprises: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ],
    Teams: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ]
}
function onAction(e, id, callback) {
    if (e.target.tagName !== 'TD' && e.target.closest('span')) {
        let action = e.target.closest('span').dataset.title;
        callback(id, action);
    }
}

class CommonTable extends Component {
    constructor() {
        super();
        this.state = {
            selectedOption: {}
        }
        this.options = [];
    }

    componentWillReceiveProps(nextProps) {
        if (this.options.length === 0) {
            this.options = dropdown[nextProps.tabs[0].name];
            this.setState({ selectedOption: this.options[0] });
        }
    }

    selectedElements(e) {
        this.props.select(e);
    }

    displayTableDataHandler(listData, index) {
        if (this.props.tabs[0].name === 'Enterprises') {
            //let rowclass = listData.id === this.props.editedItem ? 'active ' : '';
            let rowclass = '';

            return <tr key={index} className={rowclass}>
                <td className="table-box">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" checked={this.props.checkedItems.get(listData.id) === undefined ? false : this.props.checkedItems.get(listData.id)} data-selecteditems={listData.id} onChange={(e) => this.selectedElements(e)} id={"checkbox" + listData.id} />
                        <label className="custom-control-label" htmlFor={"checkbox" + listData.id}></label>
                    </div>
                </td>
                <td>
                    <div>{listData.eInfo.name}</div>
                </td>
                <td>
                    <div>{listData.eInfo.owner ? listData.eInfo.owner.name : ''}</div>
                </td>
                <td>
                    <div>{moment.utc(listData.eInfo.createdAt).local().format("YYYY/MM/DD HH:mm A")}</div>
                </td>
                <td onClick={(event) => onAction(event, listData.id, this.props.userAction)}>
                    <div>
                        {this.props.status === 'active' &&
                            <React.Fragment>
                                <span className="pointer mr-3"
                                    data-title="editEnterprise"
                                    style={{ color: '#2f63dc' }}>
                                    <Trans>edit</Trans>
                                </span>
                                |
                            </React.Fragment>
                        }
                        {this.props.status === 'active' &&
                            <React.Fragment>
                                <span className="pointer mr-3 ml-3"
                                    data-title="makeInactive"
                                    style={{ color: '#d8a504' }}>
                                    <Trans>Inactivate</Trans>
                                </span>|
                            </React.Fragment>
                        }
                        {this.props.status === 'inactive' &&
                            <React.Fragment>
                                <span className="pointer mr-3 ml-3"
                                    data-title="makeActive"
                                    style={{ color: '#d8a504' }}>
                                    <Trans>Activate</Trans>
                                </span>|
                            </React.Fragment>
                        }
                        <span className="pointer ml-3"
                            data-title="deleteEnterprise"
                            style={{ color: '#fc7169' }}>
                            <Trans>Delete</Trans>
                        </span>
                    </div>
                </td>
            </tr>

        } else if (this.props.tabs[0].name === 'Teams') {
            let rowclass = listData.id === this.props.editedItem ? 'active ' : '';
            return <tr key={index} className={rowclass}>
                <td className="table-box">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" checked={!!this.props.checkedItems.get(listData.id)} data-selecteditems={listData.id} onChange={(e) => this.selectedElements(e)} id={"checkbox" + listData.id} />
                        <label className="custom-control-label" htmlFor={"checkbox" + listData.id}></label>
                    </div>
                </td>
                <td>
                    <div>{listData.tInfo.name}</div>
                </td>
                <td>
                    <div>{listData.tInfo.eName !== 'Unassigned' ? listData.tInfo.eName : ''}</div>
                </td>
                <td>
                    <div>{listData.tInfo.owner ? listData.tInfo.owner.name : ''}</div>
                </td>
                <td>
                    <div>{moment.utc(listData.tInfo.createdAt).local().format("YYYY/MM/DD HH:mm A")}</div>
                </td>
                <td onClick={(event) => onAction(event, listData.id, this.props.userAction)}>
                    <div>
                        {this.props.status === 'active' &&
                            <React.Fragment>
                                <span className="pointer mr-3"
                                    data-title="editTeam"
                                    style={{ color: '#2f63dc' }}>
                                    <Trans>edit</Trans>
                                </span>
                                |
                            </React.Fragment>
                        }
                        {this.props.status === 'active' &&
                            <React.Fragment>
                                <span className="pointer mr-3 ml-3"
                                    data-title="makeInactive"
                                    style={{ color: '#d8a504' }}>
                                    <Trans>Inactivate</Trans>
                                </span>|
                            </React.Fragment>
                        }
                        {this.props.status === 'inactive' &&
                            <React.Fragment>
                                <span className="pointer mr-3 ml-3"
                                    data-title="makeActive"
                                    style={{ color: '#d8a504' }}>
                                    <Trans>Activate</Trans>
                                </span>|
                            </React.Fragment>
                        }
                        <span className="pointer ml-3"
                            data-title="deleteTeam"
                            style={{ color: '#fc7169' }}>
                            <Trans>Delete</Trans>
                        </span>
                    </div>
                </td>
            </tr>

        }
    }

    displayTitleHandler() {
        return <tr>{this.props.listHead.map((head, index) => {
            if (head.title === 'select')
                return (
                    <th key={index} scope="col" className="table-box">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="checkboxall" checked={this.props.checkedItems.get('all') === undefined ? false : this.props.checkedItems.get('all')} data-selecteditems="all" onChange={(e) => this.selectedElements(e)} />
                            <label className="custom-control-label" htmlFor="checkboxall"></label>
                        </div>
                    </th>
                )
            else
                return <th key={index} scope="col"><Trans>{head.title}</Trans></th>

        })}</tr>
    }

    titleCaseConversion(text) {
        let result = text.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    changeBulkStatus(status) {
        this.props.changeBulkStatus(status);
    }

    render() {
        let props = this.props;
        return (
            <div className="row no-gutters my-3" style={{overflowX: 'auto'}}>
                <div className="nav-wrapper">
                    <Navtab tabs={props.tabs} style={{ backgroundColor: 'rgb(34, 41, 82)', color: '#fff' }} />
                </div>

                <div className="row no-gutters mt-3 mb-2 user-filter w-100">
                    <Select
                        value={this.props.filterselect}
                        onChange={this.props.filterData}
                        options={this.options}
                        className="react-select react-select-custom-width-common-table"
                        isSearchable={false}
                        classNamePrefix="react-select"
                    />
                    {props.selected > 0 && (
                        <React.Fragment>
                            <div className="ml-3">
                                {this.props.status === 'active' ? (
                                    <button onClick={() => { this.changeBulkStatus('inactive') }} className="btn btn-disable mr-3"><Trans>inactiveSelected</Trans></button>
                                ) : (
                                        <button onClick={() => { this.changeBulkStatus('active') }} className="btn btn-disable mr-3"><Trans>activeSelected</Trans></button>
                                    )}
                            </div>
                            {this.props.tabs[0].name === 'Enterprises' &&
                                <div><button onClick={props.deleteEnterprises} className="btn btn-delete"><Trans>deleteSelected</Trans></button></div>
                            }
                            {this.props.tabs[0].name === 'Teams' &&
                                <div><button onClick={props.deleteTeams} className="btn btn-delete"><Trans>deleteSelected</Trans></button></div>
                            }
                        </React.Fragment>
                    )}
                </div>


                {props.list && props.list.length > 0 ? (
                    <table className="table table-bordered" id="table">
                        <thead>
                            {this.displayTitleHandler()}
                        </thead>
                        <tbody>

                            
                            {props.list.map((listData, index) => this.displayTableDataHandler(listData, index))}
                        </tbody>
                    </table>
                ) : (
                        <div className="w-100 text-center alert alert-custom">
                            {!props.progress ? <span><Trans>No data found</Trans></span> : <span><Trans>Loading...</Trans></span>}
                        </div>
                    )}
            </div>
        )
    }
}

export default CommonTable;