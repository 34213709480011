import {
  FETCH_ENTERPRISES_BEGIN,
  FETCH_ENTERPRISES_SUCCESS,
  FETCH_LOADMORE_ENTERPRISES_BEGIN,
  CREATE_ENTERPRISE_SUCCESS,
  START_EDIT,
  RESET_EDIT,
  UPDATE_ENTERPRISE_SUCCESS,
  DELETE_ENTERPRISE_SUCCESS,
  UPDATE_STATUS_SUCCESS,
  UNEXPECTED_ERROR
} from './enterpriseActions';

const initialState = {
  items: [],
  loading: false,
  error: null,
  LastEvaluatedKey: null,
  status: 'active',
  progress: false,
  editedItem: null,
  loadmore: false,
  headers: [    
      {
          type: 'checkbox'
      },
      {
          name: 'Name'
      },
      {
          name: 'Owner Name'
      },
      {
          name: 'Created Date'
      },
      {
          name: 'Action'
      }
  ]
};


export default function enterpriseReducer(state = initialState, { type, payload }) {
  switch (type) {
   
    case FETCH_ENTERPRISES_BEGIN:
      return {
        ...state,
        progress: true,
        error: null,        
        LastEvaluatedKey: null,
        status: 'active',  
        editedItem: null
      };
    case FETCH_LOADMORE_ENTERPRISES_BEGIN:
      return {
        ...state,
        loadmore: true
      }

    case FETCH_ENTERPRISES_SUCCESS:
      return {
        ...state,
        progress: false,
        loadmore: false,
        ...payload
      };
    // 
    case CREATE_ENTERPRISE_SUCCESS:
      let updatedItems = [payload, ...state.items];
      return {
        ...state,
        items: updatedItems
      };
    // 
    case START_EDIT:
      return {
        ...state,
        editedItem: payload,
      };
      case RESET_EDIT: 
        return {
          ...state,
          editedItem: null
        }

    case UPDATE_ENTERPRISE_SUCCESS: {
      const updatedItems = [...state.items];
      const itemIndex = updatedItems.findIndex(item => payload.id === item.id);
      updatedItems[itemIndex] = payload;
      return {
        ...state,
        editedItem: null,
        items: updatedItems
      }
    }
    case DELETE_ENTERPRISE_SUCCESS: {
      const updatedItems = state.items.filter(item => !payload.ids.includes(item.id));
      return {
        ...state,
        items: updatedItems,
      }
    }
    case UPDATE_STATUS_SUCCESS: {
      const updatedItems = state.items.filter(item => !payload.ids.includes(item.id));
      return {
        ...state,
        items: updatedItems,
      }
    }

    case UNEXPECTED_ERROR: {
      return {
        ...state,
        error: payload.message
      }
    }
    
    default:
      return state;
  }
}