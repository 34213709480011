import React from 'react'

const Navtab = (props => {
    return (
        <ul className="nav">
            {props.tabs.map((tab) =>
            <li key={tab.id} style={props.style}>
            {tab.name}
            </li>
            )}            
        </ul>
    )
})

export default Navtab;