import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { DynamoDB } from 'aws-sdk';
import { isEmpty } from 'lodash';
import logininfo from '../../assets/img/login-img.png';
import logo from '../../assets/img/logo-hmi-white.png';
import '../signin/signin.scss';
import '../form.scss';
import { ClipLoader } from 'react-spinners';
import { Trans } from "react-i18next";
import { withI18n } from "react-i18next";
import Logo from '../../components/logo';
import { ENVIRONMENTS }  from '../../environment';
import {passwordValidate, passwordValidateReturn} from '../../utils/utilityfunctions';
import styles from '../../scss/modules/loader.module.scss';

const userTable = ENVIRONMENTS.dynamoDB['users'];
const emptyvalue= <Trans>Passwords is not match.</Trans>;

class ChangePassword extends Component {

    state = {
        newpassword: null,
        confirmpassword: null,
        loading: false,
        showValidationInfo: false,
        doesnotmatch: false,
        errormessage: '',
    }

    componentDidMount() {       
    }
    inputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
            doesnotmatch: false
        });
    }

    passwordChange = (event) => {
        const pswd = event.target.value;
        this.setState({
            newpassword: event.target.value,
            doesnotmatch: false
        });
        passwordValidate(pswd);
    }
    validationInfo = (show) => {
        this.setState({
            showValidationInfo: show
        });
        if (show && this.state.newpassword) {
            setTimeout(() => {
                passwordValidate(this.state.newpassword);
            }, 10)
        }
    }
    SubmitChangePassword(event, setSession) {
        event.preventDefault();

        const { newpassword, confirmpassword } = this.state;
        if (!passwordValidateReturn(newpassword)) {
            this.validationInfo(true);
            return;
        }
        if (newpassword !== confirmpassword) {
            this.setState({ doesnotmatch: true });
            return false;
        }
        const { username, password } = this.props.location.state;
        if ((newpassword) && !this.state.loading) {
            this.setState({ loading: true });
            Auth.signIn(username, password)
                .then(user => {
                    user.completeNewPasswordChallenge(newpassword, user.challengeParam.requiredAttributes, {
                        onSuccess: (session) => {

                            Auth.currentUserCredentials().then(credentials => {

                                let essCred = Auth.essentialCredentials(credentials);
                                let docClient = new DynamoDB.DocumentClient({
                                    credentials: essCred
                                });

                                let tempid = `unconfirmed-${user.username}`;
                                docClient.get({
                                    TableName: userTable,
                                    Key: {
                                        id: tempid
                                    }
                                }, (err, data) => {
                                    if (err) {
                                        this.setState({ loading: false, valid: false, errormessage: err.message });
                                    } else {

                                        if (!isEmpty(data)) {
                                            let dataItem = data.Item;
                                            dataItem.userInfo['userName'] = user.username;

                                            let item = {
                                                ...dataItem,
                                                id: essCred.identityId,
                                            }
                                            var params = {
                                                RequestItems: {
                                                 [userTable]: [
                                                     {
                                                    PutRequest: {
                                                     Item: item
                                                    }
                                                   }, 
                                                     {
                                                    DeleteRequest: {
                                                     Key: {
                                                      id: tempid                                                      
                                                     }
                                                    }
                                                   }                                                    
                                                  ]
                                                }
                                               };
                                               docClient.batchWrite(params, (err, data) => {
                                                 if (err) {
                                                    this.setState({ loading: false, valid: false, errormessage: err.message });
                                                 } 
                                                 this.props.passwordChanged();                                                      
                                               });
                                        } else {
                                            this.setState({ loading: false, valid: false, errormessage: 'User does not exixts' });
                                        }
                                    }
                                });
                            });                          
                        },
                        onFailure: (err) => {
                            this.setState({ loading: false, valid: false, errormessage: err.message });
                        }
                    });
                })
                .catch(err => {
                    this.setState({ loading: false, valid: false, errormessage: err.message }, () => {
                        if(err.message === 'Incorrect username or password.')
                            this.props.history.replace('/');
                    });
                });
        } else {
            this.setState({ valid: false, errormsg: emptyvalue });
        }
    }

    render() {
        let loaderClass = null;
        const { t, profile, authProgress } = this.props;
        if (this.state.loading) {
            loaderClass = 'loading';
        }
        return (       
                !authProgress ? (
                    (!profile && this.props.location.state) ? (
                        <div className="Login_container">
                            <ClipLoader
                                className="clipLOader"
                                sizeUnit={"px"}
                                size={50}
                                color={'#fff'}
                                loading={this.state.loading}
                            />
                            <div className={"Login " + loaderClass}>
                                <div className="d-none d-sm-block">
                                    <img className="Login_info" src={logininfo} alt="" />
                                    <div className="Login_logo">
                                        <img className="Logo" src={logo} alt="" />
                                    </div>
                                </div>
                                <div className="Login_form">                                   
                                    <Logo/>
                                    <h1><Trans>changePassword</Trans></h1>
                                    <form onSubmit={(event) => this.SubmitChangePassword(event)}>
                                        <p style={{ color: "#c5bebe" }}><Trans>username</Trans>: {this.props.location.state.username}</p>
                                        {!this.state.valid && <p className="Login_error pl-2">{this.state.errormessage}</p>}
                                        <div className="form-group position-relative info-box">
                                            <input className="form-control" type="password"
                                                onBlur={() => { this.validationInfo(false) }}
                                                onFocus={() => { this.validationInfo(true) }}
                                                name="newpassword"
                                                placeholder={t('newPassword')} onChange={this.passwordChange.bind(this)} />
                                            {this.state.showValidationInfo &&
                                                (
                                                    <div id="pswd_info" className="error-info">
                                                        <h4><Trans>passwordMustMeettheFollwingRequirements</Trans></h4>
                                                        <ul>
                                                            <li id="letter" className="invalid"><Trans>At least</Trans><strong><Trans>one lowercase letter</Trans></strong></li>
                                                            <li id="number" className="invalid"><Trans>At least</Trans><strong><Trans>one number</Trans></strong></li>
                                                            <li id="length" className="invalid"><Trans>Be at least</Trans><strong><Trans>8 characters</Trans></strong></li>
                                                        </ul>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="form-group position-relative error-box">
                                            <input className="form-control" type="password" name="confirmpassword" placeholder={t('confirmPassword')} onChange={this.inputChange.bind(this)} />
                                            {this.state.doesnotmatch &&
                                                (
                                                    <div className="error-info error">
                                                        <p className="mb-0"><Trans>passwordsDoesNotMatch</Trans></p>
                                                    </div>
                                                )}
                                        </div>
                                        <div>
                                            <button type="submit" className="btn"><Trans>changePassword</Trans></button>

                                        </div>
                                    </form>                                     
                                </div>
                            </div>
                        </div>
                    ) : (
                            <Redirect to={{
                                pathname: '/',
                                state: { from: this.props.location }
                            }}
                            />
                        )
        ):
            <div className={styles.appLoading}>
                <div className={styles.hmiLoadingSpinner}></div>
            </div>       
        )
    }
}
export default withI18n()(ChangePassword);